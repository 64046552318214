#CancelRequestPrompt{
    display:block;
}

.CancelRequest-Body{
    display:block;
    padding:30px;
    text-align: center;
}

.CancelRequest-Body img {
    width: 50px;
    height: 50px;
}

.CancelRequest-Title{
    display:block;
    margin-top:20px;
    margin-bottom:15px;
    font-size: 20px;
    font-weight: bold;
    color:#000000;
}

.CancelRequest-Description{
    font-size: 14px;
    color:#000000;
}

.CancelRequest-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.CancelRequest-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.CancelRequest-CancelButton{
    display:block;
    float:left;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}

.CancelRequest-SubmitButton{
    display:block;
    float:right;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.CancelRequest-CancelButtonDisabled{
    display:block;
    float:left;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    cursor: pointer;
}

.CancelRequest-SubmitButtonDisabled{
    display:block;
    float:right;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    cursor: pointer;
}