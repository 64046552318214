#Footer {
    display:block;
    width:100%;
    background-color: #F2F2F2;
    font-size: 12px;
    color: #000000;
}

#Footer::after{
    display:table;
    content:"";
    clear:both;
}

.FooterLeft{
    display:block;
    width:45%;
    float:left;
}

.FooterRight{
    display:block;
    width:55%;
    float:right;
}

.FooterLeft-Copyright{
    display:block;
    padding: 20px 30px 20px 30px;
    text-align:left;
}

.FooterRight-PrivacyNotice{
    display:inline-block;
    padding: 20px 30px 20px 30px;
    float:right;
    color:#CCCCCC;
}

.FooterRight-TermsAndConditions{
    display:inline-block;
    padding: 20px 30px 20px 30px;
    float:right;
    color:#CCCCCC;
}

.FooterRight a, .FooterRight a:hover {
    color:#CCCCCC;
}