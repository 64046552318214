

.UpdateStocksForm-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.UpdateStocksForm-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.UpdateStocksForm-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.UpdateStocksForm-Body{
    display:block;
    padding:30px;
}
.UpdateStocksForm-InputLabelSmall {
    font-size: 12px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:5px;
}

.UpdateStocksForm-InputValue{
    font-size: 14px;
    text-align: left;
    color: #000000;
    margin-bottom:15px;
}

.UpdateStocksForm-InputValueBlue{
    font-size: 14px;
    text-align: left;
    color: #1B91FF;
    margin-bottom:30px;
}



.UpdateStocksForm-InputLayout{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.UpdateStocksForm-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

.UpdateStocksForm-InputLayout-Left{
    display:block;
    width:50%;
    float:left;
}

.UpdateStocksForm-InputLeft{
    display:block;
    margin-right:15px;
}

.UpdateStocksForm-InputLayout-Right{
    display:block;
    width:50%;
    float:right;
}

.UpdateStocksForm-InputRight{
    display:block;
    margin-left:15px;
}

.UpdateStocksForm-NumberInput {
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -webkit-appearance: none; 
}
.UpdateStocksForm-NumberInput::-webkit-inner-spin-button,
.UpdateStocksForm-NumberInput::-webkit-outer-spin-button{
    -webkit-appearance: none; 
}


.UpdateStocksForm-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

.UpdateStocksForm-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.UpdateStocksForm-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.UpdateStocksForm-CancelButton{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}

.UpdateStocksForm-UpdateButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.UpdateStocksForm-CancelButtonDisabled{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}


.UpdateStocksForm-UpdateButtonDisabled{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.UpdateStocksForm-ErrorText {
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: #FF5A26;
    margin-bottom: 10px;
}

.UpdateStocksForm-Gone{
    display: none;
}