#PledgedListTableContainer {
    max-height: 70vh;
    overflow: auto;
    text-align: center;
    margin: 30px;
    background: #FAFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.PledgedListTable-HeaderTitle{
    display:block;
    padding: 30px;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    color:#000000;
}

#PledgedListTable-ProgressRow{
    display:block;
    padding: 30px;
    background-color: #F7F7F7;
}

#PledgedListTable-ProgressRow::after{
    display:table;
    content:"";
    clear:both;
}

.PledgedListTable-ProgressRowLeft {
    display:block;
    width:70%;
    float:left;
    text-align:left;
}

.PledgedListTable-ProgressRowRight {
    display:block;
    width:30%;
    float:right;
    text-align: left;
}

.PledgedListTable-Pretitle{
    font-size: 12px;
    font-weight: bold;
    color:#666666;
    margin-bottom: 5px;
}

.PledgedListTable-Title{
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 5px;
}

.PledgedListTable-Subtitle{
    font-size: 12px;
    color: #000000
}

#PledgedListTable-Contents{
    padding:30px;
}

#PledgedListTable{
    border-collapse: collapse;
    width: 100%;
    border:0px;
    border: 1px solid #dddddd;
}

#PledgedListTable tr:first-child {
    background-color: #ECECEC;;
}

#PledgedListTable tr:nth-child(even) {
    background-color: #F6F6F6;;
}

#PledgedListTable .thRow {
  padding: 10px 15px 10px 15px;
  border-right: 1px solid #dddddd;
}
#PledgedListTable .thRowLast {
    padding: 10px 15px 10px 15px;
  }
  
#PledgedListTable th {
    border-bottom: 1px solid #dddddd;
    border-left: 0px;
    border-right: 0px;
    padding-top:5px;
    padding-bottom:5px;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #666666;
}

#PledgedListTable td{
    border: 0px;
    padding: 15px 15px 5px 15px;
    font-size: 12px;
    text-align: left;
    color: #000000;
}

#PledgedListTable td:last-child{
    border: 0px;
    padding: 5px 15px 5px 15px;
    font-size: 12px;
    text-align: left;
    color: #000000;
}

.PledgedListTable-ActionButtonsLayout{
    display:block;
}

.PledgedListTable-ActionButtonsLayout::after{
    display:table;
    content:"";
    clear:both;
}

.PledgedListTable-ActionButtonsLayout-Left{
    display: inline-block;
}

.PledgedListTable-ActionButton-EditQuantity{
    text-align: center;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:5px;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.PledgedListTable-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

#PledgedListTable-RequestsPlaceholder{
    display:block;
    padding:30px 30px 90px 30px;
    background: #F7F7F7;
    text-align:left;
    font-size: 14px;
    color: #000000;
}