

.VerificationPromptVendor-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.VerificationPromptVendor-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.VerificationPromptVendor-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.VerificationPromptVendor-Body{
    display:block;
    padding:30px;
}
.VerificationPromptVendor-InputLabelSmall {
    font-size: 12px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:5px;
}

#VerificationPromptVendor-Tier1{
    margin-right:10px;
}

#VerificationPromptVendor-Tier2{
    margin-right:10px;
}

#VerificationPromptVendor-RadioInputLabelLeft {
    display:inline-block;
    padding:5px;
    margin-right:50px;
    box-sizing: border-box;
    font-size: 14px;
    color: #000000; 
    cursor: pointer;
}

.VerificationPromptVendor-VerificationCheckbox{
    display:block;
    padding:20px 30px 20px 30px;
    background-color: #EBF5FF;
}

#VerificationPromptVendor-Confirmation{
    margin-right:10px;
}

#VerificationPromptVendor-ConfirmationLabel{
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.VerificationPromptVendor-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

.VerificationPromptVendor-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.VerificationPromptVendor-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.VerificationPromptVendor-CancelButton{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}

.VerificationPromptVendor-UpdateButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.VerificationPromptVendor-CancelButtonDisabled{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}


.VerificationPromptVendor-UpdateButtonDisabled{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

#VerificationPromptVendor-TierDescription{
    display:block;
    padding:15px;
    background-color:#EBF5FF;
    text-align: left;
    font-size: 14px;
    color: #1B91FF;
  }