#ResetEmailPasswordPage {
  margin: auto;
  margin-top: 50px;
  display: block;
  width: 420px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

#ResetEmailPasswordPageContents1 {
  display: block;
  padding: 30px 30px 0px 30px;
}

#ResetEmailPasswordPageContents2 {
  display: block;
  padding: 39px 30px 0px 30px;
}

#ResetEmailPasswordPage-Logo {
  width: 250px;
  height: 100px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

#ResetEmailPasswordPage-Title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding: 30px 30px 15px 30px;
  color: #000000;
  border-top: 1px solid #dbdbdb;
}

#ResetEmailPasswordPage-SendButton {
  height: 43px;
  margin-top: 10px;
  text-align: center;
  display: block;
  padding: 10px;
  border-radius: 5px;
  background-color: #1b91ff;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

#ResetEmailPasswordPage-SendButton:hover {
  background: #176bd8;
}

#ResetEmailPasswordPage-SendButtonDisabled {
  height: 43px;
  margin-top: 10px;
  text-align: center;
  display: block;
  padding: 10px;
  border-radius: 5px;
  background-color: #E6E6E6;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

#ResetEmailPasswordPageContents-SendButtonLoading {
  margin: auto;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#ResetEmailPasswordPage-Header {
  margin: 0 auto;
  width: 420px;
  padding-top: 62px;
  margin-bottom: -20px;
}

#ResetEmailPasswordPage-HeaderTitle {
  font-size: 14px;
  line-height: 19px;
  color: #1b91ff;
  cursor: pointer;
}

#ResetEmailPasswordPage-HeaderIcon {
  width: 15.34px;
  height: 15.34px;
  margin: 0px 5px 2px 0px;
}

#ResetEmailPasswordPage-Body {
  background: #f5f5f5;
  min-height: 100vh;
}

.ResetEmailPasswordPage-InputLabel {
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  font-weight: bold;
}

.ResetEmailPasswordPage-Input {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 360px;
  height: 37px;
  padding-left: 10px;
  border: 1px solid #dbdbdb;
}

#ResetEmailPasswordPage-DataPrivacy {
  line-height: 1.4;
  font-size: 14px;
  text-align: center;
  color: #000000;
  padding: 15px 0px 30px 0px;
}

#ResetEmailPasswordPage-DataPrivacy a {
  text-decoration: none;
  color: #1b91ff;
}

#ResetEmailPasswordPage-PasswordInformation {
  background: #ebf5ff;
  text-align: center;
  align-items: center;
}

#ResetEmailPasswordPage-PasswordInformation-Description {
  font-size: 10px;
  color: #4f4f4f;
  padding: 15px 35px 15px 35px;
  margin-top: 10px;
}

#ResetEmailPasswordPage-ResetLabel {
  font-size: 12px;
  text-align: center;
color : #1B91FF;
}

#ResetEmailPasswordPage-ResetLabel :hover {
    text-decoration: underline;
    color : #1B91FF;
    cursor: pointer
}

#ResetEmailPasswordPage-Reset {
    font-size: 12px;
    text-align: center;
    padding: 15px 15px 15px 15px;
  }

.ResetEmailPasswordPage-InputError {
  margin-top: 10px;
  width: 360px;
  height: 37px;
  padding-left: 10px;
  border: 1px solid #ff5a26;
}

.ResetEmailPasswordPage-InputErrorMessage {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #ff5a26;
  padding-top: 5px;
  margin-bottom: 10px;
}

.ResetEmailPasswordPage-InputContainer {
  width: 360px;
}
