#AdminVerificationListContainer{
    text-align: center;
    margin: 20px;
    padding: 30px;
    background: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.AdminVerificationList-Header {
    display:block;
    padding-bottom:30px;
}
.AdminVerificationList-Header::after {
    display:table;
    content:"";
    clear:both;
}

.AdminVerificationList-HeaderLeft {
    display:block;
    width:50%;
    float:left;
}

.AdminVerificationList-HeaderRight {
    display:block;
    width:50%;
    float:right;
}

.AdminVerificationList-Header::after {
    display:table;
    content:"";
    clear:both;
}

.AdminVerificationList-InputHolderLarge{
    display: block;
    width:425px;
    float:right;
    margin-right:15px;
}

.AdminVerificationList-HeaderTitle {
    display:block;
    font-size: 20px;
    font-weight: bold;
    color:#000000;
    text-align: left;
    padding-bottom:10px;
}

#AdminVerificationList{
    border-collapse: collapse;
    width: 100%;
    border:0px;
    border: 1px solid #dddddd;
}

.AdminVerificationList-ActionButtonsLayout{
    display:block;
}

.AdminVerificationList-ActionButtonsLayout::after{
    display:table;
    content:"";
    clear:both;
}

.AdminVerificationList-ActionButtonsLayout-Left{
    display: inline-block;
}
.AdminVerificationList-ActionButtonsLayout-Right{
    display: inline-block;
}

#AdminVerificationList-ProgressBar{
    display:block;
    width:100%;
    height:4px;
    background-color:#1B91FF;
}
#AdminVerificationList-ProgressBarComplete{
    display:block;
    width:100%;
    height:4px;
    background-color: #8CD867;
}
#AdminVerificationList-ProgressBar::-webkit-progress-bar{
    background-color: #D7D7D7;
}
#AdminVerificationList-ProgressBar::-webkit-progress-value{
    background: #1B91FF;
}
#AdminVerificationList-ProgressBarComplete::-webkit-progress-value{
    background: #8CD867;
}
#AdminVerificationList-ProgressBar::-moz-progress-bar{
    background: #D7D7D7;
}
#AdminVerificationList-ProgressBar::-moz-progress-value{
    background: #1B91FF;
}
#AdminVerificationList-ProgressBarComplete::-webkit-progress-value{
    background: #8CD867;
}

.AdminVerificationList-ProgressValue{
    display:block;
    text-align: left;
    margin-bottom:5px;
}

.AdminVerificationList-ActionButton-PrimaryButton{
    text-align: center;
    width: 94px;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:10px 5px 10px 5px;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.AdminVerificationList-ActionButton-SecondaryButton{
    text-align: center;
    width: 94px;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:10px 5px 10px 5px;
    border: 1px solid #1B91FF;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 10px;
    font-weight: bold;
    color: #1B91FF;
    cursor: pointer;
}


.AdminVerificationList-ActionButton-SecondaryButtonSmall{
    text-align: center;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:10px 5px 10px 5px;
    border: 1px solid #1B91FF;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 10px;
    font-weight: bold;
    color: #1B91FF;
    cursor: pointer;
}

#AdminVerificationList tr:first-child {
    background-color: #ECECEC;;
}

#AdminVerificationList tr:nth-child(even) {
    background-color: #F6F6F6;;
}

#AdminVerificationList .thRow {
  padding: 10px 15px 10px 15px;
  border-right: 1px solid #dddddd;
}

#AdminVerificationList .thRowLast {
    padding: 10px 15px 10px 15px;
}
  
#AdminVerificationList th {
    border-bottom: 1px solid #dddddd;
    border-left: 0px;
    border-right: 0px;
    padding-top:5px;
    padding-bottom:5px;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #666666;
}

#AdminVerificationList td{
    border: 0px;
    padding: 5px 15px 5px 15px;
    font-size: 12px;
    text-align: left;
    color: #000000;
}

.AdminVerificationList-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

#AdminVerificationList-TextInput{
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
}

#None {
    display:none;
}