#AddRequestForm{
    display:block;
}

.AddRequest-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.AddRequest-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.AddRequest-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.AddRequest-Body{
    display:block;
    padding:30px;
}

.AddRequest-InputLayout{
    display:block;
    margin-top:10px;
}

.AddRequest-InputLabelSmall {
    font-size: 12px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:5px;
}

.AddRequest-TextInput{
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -moz-appearance:textfield;
}

.AddRequest-NumberInput {
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -webkit-appearance: none; 
}
.AddRequest-NumberInput::-webkit-inner-spin-button,
.AddRequest-NumberInput::-webkit-outer-spin-button{
    -webkit-appearance: none; 
}


.AddRequest-InputLayout{
    display:block;
}

.AddRequest-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

.AddRequest-InputLayout-Left{
    display:block;
    width:50%;
    float:left;
}

.AddRequest-InputLeft{
    display:block;
    margin-right:15px;
}

.AddRequest-InputLayout-Right{
    display:block;
    width:50%;
    float:right;
}

.AddRequest-InputRight{
    display:block;
    margin-left:15px;
}

.AddRequest-ErrorText{
    display:block;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #FF5A26;
}


.AddRequest-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.AddRequest-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.AddRequest-CancelButton{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}

.AddRequest-SubmitButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.AddRequest-CancelButtonDisabled{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.AddRequest-SubmitButtonDisabled{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.Gone {
    display:none;
}

#AddRequest-FileInputError{
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #FF5A26;
    background-color: #ffffff;
}

#AddRequest-FileInput{
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
}

#Hidden {
    visibility: hidden;
}

#file-upload-wrapper {
    position: relative;
    width: 100%;
    padding: 20px 20px;
}

#file-upload-error {
    position: relative;
    width: 100%;
    padding: 20px 20px;
}

#file-upload-wrapper:after {
    content: attr(data-text);
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: 5px 10px;
    display: block;
    width: 100%;
    pointer-events: none;
    border: 1px solid #dddddd;
    z-index: 20;
    color: #999;
    border-radius: 5px;
  }


  #file-upload-error:after {
    content: attr(data-text);
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: 5px 10px;
    display: block;
    width: 100%;
    pointer-events: none;
    border: 1px solid #FF5A26;
    z-index: 20;
    color: #999;
    border-radius: 5px;
  }

#file-upload-wrapper:before {
    content: 'Upload';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    color:#fff;
    padding: 6px 25px 6px 25px;
    background: #1877F2;;
    z-index: 25;
    font-size: 14px;
    font-weight: bold;
    pointer-events: none;
    border-radius: 5px;
}

#file-upload-error:before {
    content: 'Upload';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    color:#fff;
    padding: 6px 25px 6px 25px;
    background: #1877F2;
    z-index: 25;
    font-size: 14px;
    font-weight: bold;
    pointer-events: none;
    border-radius: 5px;
}

.file-upload-field {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
    border: 1px solid #dddddd;
}

.AddRequest-Img {
    text-align: center;
    width: 100%;
    height: auto;
}