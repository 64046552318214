#AddCityForm {
  display: block;
}

.AddCityForm-Header {
  display: block;
  padding: 30px;
  background-color: #f4f4f4;
  border-radius: 20px 20px 0px 0px;
}

.AddCityForm-PreHeaderTitle {
  font-size: 14px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #666666;
  margin-bottom: 5px;
}

.AddCityForm-HeaderTitle {
  font-size: 20px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #212121;
}

.AddCityForm-Body {
  display: block;
  padding: 30px;
  text-align: center;
  background-color: #ffffff;
}

.AddCityForm-Notice {
  display: block;
  padding: 15px;
  background-color: #ebf5ff;
  font-size: 14px;
  text-align: left;
  word-wrap: break-word;
  color: #1b91ff;
}

.AddCityForm-InputLayout {
  display: block;
  margin-top: 30px;
}

.AddCityForm-InputLabelSmall {
  font-size: 12px;
  font-weight: 600;
  display: block;
  text-align: left;
  color: #212121;
  margin-bottom: 5px;
}

.AddCityForm-InputLabelSmall-Error {
  font-size: 12px;
  display: block;
  text-align: left;
  color: #ff5a26;
  margin-bottom: 5px;
  margin-top: 5px;
}

.AddCityForm-TextInput {
  font-size: 12px;
  text-align: left;
  color: #212121;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  -moz-appearance: textfield;
}
.AddCityForm-TextInput-Error {
  font-size: 12px;
  text-align: left;
  color: #212121;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ff5a26;
  background-color: #ffffff;
  -moz-appearance: textfield;
}
.AddCityForm-Footer {
  display: block;
  padding: 30px;
  border-top: 1px solid #c4c4c4;
}

.AddCityForm-Footer::after {
  display: table;
  content: "";
  clear: both;
}

.AddCityForm-SkipButton {
  display: block;
  float: left;
  padding: 5px 25px 5px 25px;
  text-align: center;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}

.AddCityForm-SkipButtonDisabled {
  display: block;
  float: left;
  padding: 5px 25px 5px 25px;
  text-align: center;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

.AddCityForm-SubmitButton {
  display: block;
  float: right;
  padding: 5px 25px 5px 25px;
  text-align: center;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.AddCityForm-SubmitButtonDisabled {
  display: block;
  float: right;
  padding: 5px 25px 5px 25px;
  text-align: center;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

.AddCityForm-FieldsContainer {
  display: flex;
}

.AddCityForm-FieldsInputContainerCity {
  width: 100%;
  margin-right: 5px;
}

.AddCityForm-FieldsInputContainerProvince {
  width: 100%;
  margin-left: 5px;
}

.AddCityForm-AutoComplete {
  width: 100%;
  margin-top: 30px;
}

.AddCityForm-AutoComplete ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  position: absolute;
  /* z-index: 1; */
  background: white;
  border: 1px solid #dddd;
  width: 540px;
}

.AddCityForm-AutoComplete li {
  padding: 10px 15px;
  cursor: pointer;
}

.AddCityForm-AutoComplete li:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, 0.2);
}
