

.VendorContactInformation-Header{
    display:block;
    padding:30px 30px 10px 30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.VendorContactInformation-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
}

.VendorContactInformation-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.VendorContactInformation-TabLayout{
    display:block;
    border: 1px solid #1B91FF;
    border-radius: 5px;
    overflow: hidden;
    margin:10px 30px 10px 30px;
}

.VendorContactInformation-TabLayout::after{
    display:table;
    content:"";
    clear:both;
}

.VendorContactInformation-TabLayoutLeft{
    display: block;
    width:50%;
    float: left;
}

.VendorContactInformation-TabLayoutRight{
    display: block;
    width:50%;
    float: right;
}

.VendorContactInformation-TabLayoutClassic{
    display: block;
    width:33.33%;
    float: left;
}

.VendorContactInformation-LeftActiveTab{
    padding:5px;
    background-color: #1B91FF;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    border-right: 1px solid #1B91FF;
}

.VendorContactInformation-LeftInactiveTab{
    padding:5px;
    background-color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #1B91FF;
    cursor: pointer;
}

.VendorContactInformation-ActiveTab{
    padding:5px;
    background-color: #1B91FF;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    border-left: 1px solid #1B91FF;
    border-right: 1px solid #1B91FF;
}

.VendorContactInformation-InactiveTab{
    padding:5px;
    background-color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #1B91FF;
    cursor: pointer;
    border-left: 1px solid #1B91FF;
    border-right: 1px solid #1B91FF;
}




.VendorContactInformation-RightActiveTab{
    padding:5px;
    background-color: #1B91FF;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    border-left: 1px solid #1B91FF;
}

.VendorContactInformation-RightInactiveTab{
    padding:5px;
    background-color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #1B91FF;
    cursor: pointer;
}

.VendorContactInformation-Body{
    display:block;
    padding:5px 30px 30px 30px;
}

.VendorContactInformation-BodySection{
    display:block;
    padding-top:20px;
    border-top: 1px solid #C4C4C4;
}

.VendorContactInformation-DocumentsLayout{
    display:block;
    height:260px;
    padding:10px;
    border: 1px solid #C4C4C4;
    overflow: auto;
}

.VendorContactInformation-DocumentRow{
    display:block;
    padding-top:5px;
    padding-bottom:5px;
    border-bottom: 1px solid #C4C4C4;
}

.VendorContactInformation-DocumentRow::after{
    display:table;
    content:"";
    clear:both
}

.VendorContactInformation-DocumentItem{
    display:block;
    float:left;
    text-align: left;
    line-height: 25px;
    font-size: 14px;
    color:#000000;
}

.VendorContactInformation-DocumentAction{
    display:block;
    float:right;
    width: 70px;
    height: 25px;
    line-height: 25px;
    background-color: #1B91FF;
    border-radius: 5px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color:#FFFFFF;
    cursor: pointer;
}

.VendorContactInformation-SectionTitle{
    display: block;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-bottom:15px;
}

.VendorContactInformation-ValueLayout{
    display:block;
    margin-bottom:15px;
}

.VendorContactInformation-ValueLabel{
    display:block;
    text-align:left;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
}

.VendorContactInformation-Value{
    display:block;
    text-align:left;
    font-size: 14px;
    color: #000000;
}

.VendorContactInformation-ValueBlue{
    display:block;
    text-align:left;
    font-size: 14px;
    color: #1B91FF;
}

.VendorContactInformation-InputLayout{
    display:block;
}

.VendorContactInformation-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

.VendorContactInformation-InputLayout-Left{
    display:block;
    width:50%;
    float:left;
}

.VendorContactInformation-InputLeft{
    display:block;
    margin-right:15px;
}

.VendorContactInformation-InputLayout-Right{
    display:block;
    width:50%;
    float:right;
}

.VendorContactInformation-InputRight{
    display:block;
    margin-left:15px;
}

.VendorContactInformation-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

.VendorContactInformation-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.VendorContactInformation-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.VendorContactInformation-CloseButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.VendorContactInformation-Hidden{
    display:none;
}

.VendorContactInformation-RejectData{
    margin-bottom: 15px;
    border-bottom: 1px solid #C4C4C4;
}

.VendorContactInformation-RejectDataLast{
    padding-bottom: 5px;
}

#VendorContactInformation-TierDescription{
    display:block;
    padding:15px;
    background-color:#EBF5FF;
    text-align: left;
    font-size: 14px;
    color: #1B91FF;
  }