#BusinessDocumentsFormVendorContainer {
    position: relative;
    overflow: auto;
  }
  #BusinessDocumentsFormVendor {
    display: block;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    width: 600px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  
  .BusinessDocumentsFormVendor-Header {
    display: block;
    padding: 30px;
    background-color: #f4f4f4;
    border-radius: 20px 20px 0px 0px;
  }
  
  .BusinessDocumentsFormVendor-Contents {
    display: block;
    padding: 30px;
  }
  
  #BusinessDocumentsFormVendor-Description {
    font-size: 14px;
    display: block;
    text-align: left;
    color: #000000;
    margin-bottom: 10px;
  }
  
  #BusinessDocumentsFormVendor-Pretitle {
    font-size: 14px;
    font-weight: bold;
    display: block;
    text-align: left;
    color: #666666;
  }
  
  #BusinessDocumentsFormVendor-Title {
    font-size: 20px;
    font-weight: bold;
    display: block;
    text-align: left;
    color: #000000;
  }
  
  #BusinessDocumentsFormVendor-Subtitle {
    font-size: 14px;
    display: block;
    text-align: left;
    color: #666666;
  }
  
  #BusinessDocumentsFormVendor-FilterRow {
    display: block;
    margin-bottom: 30px;
  }
  
  #BusinessDocumentsFormVendor-FilterRow::after {
    display: table;
    content: "";
    clear: both;
  }
  
  .BusinessDocumentsFormVendor-InputHolderSmall {
    display: block;
    width: 100px;
    float: left;
  }
  
  .BusinessDocumentsFormVendor-InputHolderMedium {
    display: block;
    width: 205px;
    float: left;
    margin-left: 15px;
  }

  #BusinessDocumentsFormVendor-InputLabel {
    font-size: 16px;
    font-weight: bold;
    display: block;
    text-align: left;
    color: #000000;
  }
  
  #BusinessDocumentsFormVendor-InputLabelSmall {
    font-size: 12px;
    font-weight: bold;
    display: block;
    text-align: left;
    color: #000000;
  }

  #BusinessDocumentsFormVendor-InputLabelExtraSmall {
    font-size: 12px;
    font-weight: 600;
    display: block;
    text-align: left;
    color: #000000;
  }
  
  #BusinessDocumentsFormVendor-InputSubLabel{
    display: block;
    font-size: 11px;
    font-weight: bold;
    color:#EAA80A;
  }

  #BusinessDocumentsFormVendor-RequirementsSections ul{
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  #BusinessDocumentsFormVendor-RequirementsSections li{
    padding:5px 10px 5px 10px;
    height:40px;
    border-bottom: 1px dashed #C4C4C4;
  }

  #BusinessDocumentsFormVendor-RequirementsSections li:last-child{
    padding:5px 10px 5px 10px;
    height:40px;
    border:none;
  }

  #BusinessDocumentsFormVendor-DocumentLabel{
    line-height: 30px;
  }

  #BusinessDocumentsFormVendor-TextInput {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    background-color: #ffffff;
  }

  #BusinessDocumentsFormVendor-FileInput{
    display:block;
    float:right;
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 50%;
    padding: 2px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
  }

  #BusinessDocumentsFormVendor-PaymentMethods {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    resize: none;
  }
  
  #BusinessDocumentsFormVendor-BusinessDocumentsSection{
    display:block;
    padding-top:30px;
    border-top: 1px solid #C4C4C4;
  }

  #BusinessDocumentsFormVendor-RequirementsTitle{
    display: block;
    font-size: 14px;
    color: #000000;
  }

  #BusinessDocumentsFormVendor-RequirementsSections{
    display:block;
    font-size: 14px;
    color: #000000;
  }

  #BusinessDocumentsFormVendor-RequirementsSections::after{
    display:table;
    content:"";
    clear:both;
  }

  #BusinessDocumentsFormVendor-RequirementsSectionsLeft{
    display:block;
    width:50%;
    float:left;
  }

  #BusinessDocumentsFormVendor-RequirementsSectionsLeft ul{
    padding-left: 20px;
  }

  #BusinessDocumentsFormVendor-RequirementsSectionsRight{
    display:block;
    width:50%;
    float:right;
  }

  #BusinessDocumentsFormVendor-RequirementsSectionsRight ul{
    padding-left: 0px;
  }

  #BusinessDocumentsFormVendor-TextInputError {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
  }

  #BusinessDocumentsFormVendor-FileInputError{
    display:block;
    float:right;
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 50%;
    padding: 2px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
  }

  #BusinessDocumentsFormVendor-PaymentMethodsError {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
    resize: none;
  }
  
  #BusinessDocumentsFormVendor-TextError {
    display: block;
    text-align: right;
    margin-top: 10px;
    margin-right:55px;
    font-size: 12px;
    font-weight: 600;
    color: #ff5a26;
  }
  
  .BusinessDocumentsFormVendor-ButtonsLayout {
    display: block;
    border-top: 1px solid #c4c4c4;
  }
  
  .BusinessDocumentsFormVendor-ButtonsLayout::after {
    display: table;
    content: "";
    clear: both;
  }
  
  #BusinessDocumentsFormVendor-BackButton {
    display: block;
    width:100px;
    float: left;
    margin-top: 50px;
    text-align: center;
    margin: 30px;
    padding: 10px 30px 10px 30px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
  }
  
  #BusinessDocumentsFormVendor-SaveButton {
    display: block;
    width:100px;
    float: right;
    margin-top: 50px;
    text-align: center;
    margin: 30px;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    background-color: #1877f2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }

  #BusinessDocumentsFormVendor-BackButtonDisabled {
    display: block;
    width:100px;
    float: left;
    margin-top: 50px;
    text-align: center;
    margin: 30px;
    padding: 10px 30px 10px 30px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }
  
  #BusinessDocumentsFormVendor-SaveButtonDisabled {
    display: block;
    width:100px;
    float: right;
    margin-top: 50px;
    text-align: center;
    margin: 30px;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    background-color: #1877f2;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }
  
  #BusinessDocumentsFormVendor-SaveButtonLoading {
    margin: auto;
    border: 3px solid #FFFFFF; 
    border-top: 3px solid transparent; 
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .BusinessDocumentsFormVendor-AutoComplete {
    width: 100%;
    border: 1px solid #dddddd;
  }
  
  #Hidden {
    visibility: hidden;
  }
  #BusinessDocumentsFormVendor-Gone {
    display: none;
  }

  #BusinessDocumentsFormVendor-Hint{
    display: block;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
}