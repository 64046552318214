#PledgersListTableContainer {
    max-height: 70vh;
    overflow: auto;
    text-align: center;
    margin: 30px;
    background: #FAFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.PledgersListTable-HeaderTitle{
    display:block;
    padding: 30px;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    color:#000000;
}

#PledgersListTable-ProgressRow{
    display:block;
    padding: 30px;
    background-color: #F7F7F7;
}

#PledgersListTable-ProgressRow::after{
    display:table;
    content:"";
    clear:both;
}

.PledgersListTable-ProgressRowLeft {
    display:block;
    width:70%;
    float:left;
    text-align:left;
}

.PledgersListTable-ProgressRowRight {
    display:block;
    width:30%;
    float:right;
    text-align: left;
}

.PledgersListTable-Pretitle{
    font-size: 12px;
    font-weight: bold;
    color:#666666;
    margin-bottom: 5px;
}

.PledgersListTable-Title{
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 5px;
}

.PledgersListTable-Subtitle{
    font-size: 12px;
    color: #000000
}

.PledgersListTable-ProgressValueLayout::after{
    display:table;
    content:"";
    clear:both;
}

.PledgersListTable-ProgressLabel{
    display:block;
    float:left;
    font-size: 12px;
    font-weight: bold;
    color:#666666;
    margin-bottom: 5px;
}

.PledgersListTable-ProgressValue{
    display:block;
    float:right;
    font-size: 12px;
    color:#666666;
    margin-bottom: 5px;
}

#PledgersListTable-ProgressBar{
    display:block;
    width:100%;
    height:13px;
    background-color:#1B91FF;
}
#PledgersListTable-ProgressBarComplete{
    display:block;
    width:100%;
    height:13px;
    background-color: #8CD867;
}
#PledgersListTable-ProgressBar::-webkit-progress-bar{
    background-color: #D7D7D7;
}
#PledgersListTable-ProgressBar::-webkit-progress-value{
    background: #1B91FF;
}
#PledgersListTable-ProgressBarComplete::-webkit-progress-value{
    background: #8CD867;
}
#PledgersListTable-ProgressBar::-moz-progress-bar{
    background: #D7D7D7;
}
#PledgersListTable-ProgressBar::-moz-progress-value{
    background: #1B91FF;
}
#PledgersListTable-ProgressBarComplete::-webkit-progress-value{
    background: #8CD867;
}


#PledgersListTable-Contents{
    padding:30px;
}
#PledgersListTable{
    border-collapse: collapse;
    width: 100%;
    border:0px;
    border: 1px solid #dddddd;
}

#PledgersListTable tr:first-child {
    background-color: #ECECEC;;
}

#PledgersListTable tr:nth-child(even) {
    background-color: #F6F6F6;;
}

#PledgersListTable .thRow {
  padding: 10px 15px 10px 15px;
  border-right: 1px solid #dddddd;
}
#PledgersListTable .thRowLast {
    padding: 10px 15px 10px 15px;
  }
  
#PledgersListTable th {
    border-bottom: 1px solid #dddddd;
    border-left: 0px;
    border-right: 0px;
    padding-top:5px;
    padding-bottom:5px;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #666666;
}

#PledgersListTable td{
    border: 0px;
    padding: 15px 15px 5px 15px;
    font-size: 12px;
    text-align: left;
    color: #000000;
}

#PledgersListTable td:last-child{
    border: 0px;
    padding: 5px 15px 5px 15px;
    font-size: 12px;
    text-align: left;
    color: #000000;
}

.PledgersListTable-ActionButtonsLayout{
    display:block;
}

.PledgersListTable-ActionButtonsLayout::after{
    display:table;
    content:"";
    clear:both;
}

.PledgersListTable-ActionButtonsLayout-Left{
    display: inline-block;
}
.PledgersListTable-ActionButtonsLayout-Right{
    display: inline-block;
}

.PledgersListTable-ActionButton-ContactPledger{
    text-align: center;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:5px;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.PledgersListTable-ActionButton-MarkComplete{
    text-align: center;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:5px;
    border: 1px solid #1B91FF;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 10px;
    font-weight: bold;
    color: #1B91FF;
    cursor: pointer;
}

.PledgersListTable-ActionButton-MarkCompleteDisabled{
    text-align: center;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:5px;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 10px;
    font-weight: bold;
    color: #666666;
}


.PledgersListTable-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

#PledgersListTable-RequestsPlaceholder{
    display:block;
    padding:30px 30px 90px 30px;
    background: #F7F7F7;
    text-align:left;
    font-size: 14px;
    color: #000000;
}