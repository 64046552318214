#LoginUsingEmailPage {
  margin: auto;
  margin-top: 50px;
  display: block;
  width: 420px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

#LoginUsingEmailPageContents {
  display: block;
  padding: 30px;
}

#LoginUsingEmailPageContentsFooter {
  border-top: 1px solid #dbdbdb;
  padding: 30px;
}

#LoginUsingEmailPageContentsFooter-NewToWecare {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
}

#LoginUsingEmailPage-Logo {
  width: 250px;
  height: 100px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

#LoginUsingEmailPage-Title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding: 30px 30px 15px 30px;
  color: #000000;
  border-top: 1px solid #dbdbdb;
}

#LoginUsingEmailPageContents-LoginNewButton {
  height: 43px;
  margin-top: 30px;
  text-align: center;
  display: block;
  padding: 10px;
  border-radius: 5px;
  background: #1b91ff;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

#LoginUsingEmailPageContents-LoginNewButton:hover {
  background: #176bd8;
}

#LoginUsingEmailPageContents-RegisterButton {
  height: 43px;
  margin-top: 30px;
  text-align: center;
  display: block;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  font-size: 14px;
  font-weight: bold;
  color: #1b91ff;
  cursor: pointer;
}

#LoginUsingEmailPageContents-NewLoginButtonDisabled {
  height: 43px;
  margin-top: 30px;
  text-align: center;
  display: block;
  padding: 12px;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

#LoginUsingEmailPageContents-NewLoginButtonLoading {
  margin: auto;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#LoginUsingEmailPageContents-ForgotPassword {
  text-align: left;
  font-size: 10px;
  line-height: 14px;
  color: #1b91ff;
  cursor: pointer;
}

#LoginUsingEmailPage-Header {
  margin: 0 auto;
  width: 420px;
  padding-top: 62px;
  margin-bottom: -20px;
}

#LoginUsingEmailPage-HeaderTitle {
  font-size: 14px;
  line-height: 19px;
  color: #1b91ff;
  cursor: pointer;
}

#LoginUsingEmailPage-HeaderIcon {
  width: 15.34px;
  height: 15.34px;
  margin: 0px 5px 2px 0px;
}

#LoginUsingEmailPage-Body {
  background: #f5f5f5;
  min-height: 100vh;
}

.LoginUsingEmailPage-InputLabel {
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  font-weight: bold;
}

.LoginUsingEmailPage-InputGen {
  margin-top: 10px;
  width: 360px;
  height: 37px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #dbdbdb;
}

.LoginUsingEmailPage-InputPass {
  margin-top: 10px;
  width: 360px;
  height: 37px;
  padding-left: 10px;
  padding-right: 50px;
  border: 1px solid #dbdbdb;
}

.LoginUsingEmailPage-InputErrorGen {
  margin-top: 10px;
  width: 360px;
  height: 37px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ff5a26;
}

.LoginUsingEmailPage-InputErrorPass {
  margin-top: 10px;
  width: 360px;
  height: 37px;
  padding-left: 10px;
  padding-right: 50px;
  border: 1px solid #ff5a26;
}

#LoginUsingEmailPage-DataPrivacy {
  text-align: center;
  font-size: 10px;
  line-height: 1.4;
  color: #a6a6a6;
  padding: 15px 0px 15px 0px;
}

#LoginUsingEmailPage-DataPrivacy a {
  text-decoration: underline;
  color: #a6a6a6;
}

.LoginUsingEmailPage-PasswordOption {
  position: relative;
  float: right;
  right: 12.5px;
  top: 40px;
}

.LoginUsingEmailPage-InputContainer {
  margin-bottom: 30px;
  width: 359px;
}

.LoginUsingEmailPage-InputErrorMessage {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #ff5a26;
  position: absolute;
  margin-top: 5px;
}
