#ViewIdentificationForm{
    display:block;
}

.ViewIdentification-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.ViewIdentification-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.ViewIdentification-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.ViewIdentification-Body{
    display:block;
    text-align: center;
    background-color: #E6E6E6;
}

.ViewIdentification-Body img {
    max-width: 100%;
    max-height: 100%;
}

.ViewIdentification-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.ViewIdentification-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.ViewIdentification-CloseButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.ViewIdentification-CloseButtonDisabled{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}
