#WhatToExpectPrompt{
    display:block;
}

.WhatToExpectPrompt-Header{
    display:block;
    padding:30px;
    text-align: center;
    background-color: #F4F4F4;
    border-radius: 20px 20px 0px 0px;
}
.WhatToExpectPrompt-HeaderTitle{
    display:block;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color:#000000;
}

.WhatToExpectPrompt-Body{
    display:block;
    padding:30px;
    text-align: center;
}

.WhatToExpectPrompt-Body img {
    width: 50px;
    height: 50px;
}

.WhatToExpectPrompt-Title{
    display:block;
    margin-top:20px;
    margin-bottom:15px;
    font-size: 20px;
    font-weight: bold;
    color:#000000;
}

.WhatToExpectPrompt-Description{
    text-align: left;
    font-size: 14px;
    color:#000000;
}
.WhatToExpectPrompt-ManyMore{
    margin-left:30px
}

.WhatToExpectPrompt-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.WhatToExpectPrompt-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.WhatToExpectPrompt-CancelButton{
    display:block;
    float:left;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}

.WhatToExpectPrompt-SubmitButton{
    display:block;
    float:right;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.WhatToExpectPrompt-CancelButtonDisabled{
    display:block;
    float:left;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    cursor: pointer;
}

.WhatToExpectPrompt-SubmitButtonDisabled{
    display:block;
    float:right;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    cursor: pointer;
}