#RejectRemarksForm{
    display:block;
    
}

.RejectRemarks-Header{
    display:block;
    padding:30px 30px 15px 30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.RejectRemarks-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
}

.RejectRemarks-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.RejectRemarks-Body{
    display:block;
    padding: 30px;
    text-align: left;
    font-size: 14px;
    color: #000000;
    background-color: #FFFFFF;
    word-wrap: break-word;
}


.RejectRemarks-NoImage {
    display:block;
    padding:30px;
    font-size: 20px;
    font-weight: bold;
    text-align:center;
    color: white;
}

.RejectRemarks-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.RejectRemarks-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.RejectRemarks-CloseButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.RejectRemarks-CloseButtonDisabled{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}
