#GenericImagePrompt {
  display: block;
}

.GenericImagePrompt-Body {
  display: block;
  padding: 30px 30px 15px 30px;
  text-align: center;
}

.GenericImagePrompt-Body img {
  width: 50px;
  height: 50px;
}

.GenericImagePrompt-Title {
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.GenericImagePrompt-DescriptionImage {
  display: block;
  margin-bottom: 20px;
}

.GenericImagePrompt-DescriptionImage img {
  width: 360px;
  height: 200px;
}

.GenericImagePrompt-Description {
  font-size: 14px;
  color: #000000;
}

.GenericImagePrompt-AdditionalNotes {
  display: block;
  padding: 15px 30px 15px 30px;
  margin-bottom: 20px;
  background-color: #ebf5ff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #1b91ff;
  word-wrap: break-word;
}

.GenericImagePrompt-Footer {
  display: block;
  padding: 0px 30px 30px 30px;
}

.GenericImagePrompt-Footer::after {
  display: table;
  content: "";
  clear: both;
}

.GenericImagePrompt-GotItButton {
  display: block;
  width: 100px;
  margin: auto;
  padding: 5px 30px 5px 30px;
  text-align: center;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.GenericImagePrompt-Gone {
  display: none;
}

.DonationCash-Footer {
  display: block;
  padding: 30px;
  border-top: 1px solid #ececec;
}
