#LocationFormContainer {
  position: relative;
  height: 94vh;
  overflow: auto;
}
#LocationForm {
  display: block;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 50px;
  width: 600px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}

.LocationForm-Header {
  display: block;
  padding: 30px;
  background-color: #f4f4f4;
  border-radius: 20px 20px 0px 0px;
}

.LocationForm-Contents {
  display: block;
  padding: 30px;
}

#LocationForm-Description {
  font-size: 14px;
  display: block;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

#LocationForm-Pretitle {
  font-size: 14px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #666666;
}

#LocationForm-Title {
  font-size: 20px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #000000;
}

#LocationForm-Subtitle {
  font-size: 14px;
  display: block;
  text-align: left;
  color: #666666;
}

#LocationForm-FilterRow {
  display: block;
  margin-bottom: 30px;
}

#LocationForm-FilterRow::after {
  display: table;
  content: "";
  clear: both;
}

.LocationForm-InputHolderSmall {
  display: block;
  width: 100px;
  float: left;
}

.LocationForm-InputHolderMedium {
  display: block;
  width: 205px;
  float: left;
  margin-left: 15px;
}

#LocationForm-InputLabel {
  font-size: 16px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
}

#LocationForm-InputLabelSmall {
  font-size: 12px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
}

#LocationForm-TextInput {
  font-size: 12px;
  text-align: left;
  color: #212121;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}
#LocationForm-AddressInput {
  font-size: 12px;
  text-align: left;
  color: #212121;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  resize: none;
}

#LocationForm-TextInputError {
  font-size: 12px;
  text-align: left;
  color: #212121;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ff5a26;
  background-color: #ffffff;
}
#LocationForm-AddressInputError {
  font-size: 12px;
  text-align: left;
  color: #212121;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ff5a26;
  background-color: #ffffff;
  resize: none;
}

#LocationForm-TextError {
  display: block;
  margin-bottom: 10px;
  font-size: 10px;
  color: #ff5a26;
}

.LocationForm-ButtonsLayout {
  display: block;
  border-top: 1px solid #c4c4c4;
}

.LocationForm-ButtonsLayout::after {
  display: table;
  content: "";
  clear: both;
}

#LocationForm-BackButton {
  display: block;
  float: left;
  margin-top: 50px;
  text-align: center;
  margin: 30px;
  padding: 10px 30px 10px 30px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}

#LocationForm-SaveButton {
  display: block;
  float: right;
  margin-top: 50px;
  text-align: center;
  margin: 30px;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.LocationForm-AutoComplete {
  display: block;
  width: 100%;
}

.LocationForm-AutoComplete ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  position: absolute;
  /* z-index: 1; */
  background: white;
  border: 1px solid #dddd;
  width: 540px;
}

.LocationForm-AutoComplete li {
  padding: 10px 15px;
  cursor: pointer;
}

.LocationForm-AutoComplete li:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, 0.2);
}

#Hidden {
  visibility: hidden;
}
#Gone {
  display: none;
}
