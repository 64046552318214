#TermsAndConditionContainer{
    display:block;
    margin: 20px;
    padding: 30px;
    background-color: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

#TermsAndConditionContainer-HeaderRow{
    display:block;
    text-align: left;
    width:100%;
}

.TermsAndConditionContainer-HeaderTitle {
    font-size: 24px;
    font-weight: bold;
    color:#000000;
}

#TermsAndConditionContainer-Body{
    display:block;
    width:100%;
    text-align:left;
    max-height:75vh;
    margin-top: 30px;
    overflow:auto;
}

#TermsAndConditionPage{
    display:block;
    height:100vh;
}

#TermsAndCondition-Title{
    display:block;
    font-size:16px;
    font-weight:bold;
    color:#000000;
}

#TermsAndCondition-Description{
    display:block;
    font-size:14px;
    color:#000000;
}

#TermsAndCondition-Description2{
    display:block;
    font-size:14px;
    color:#000000;
    margin-left:30px;
}

#TermsAndCondition-Description3{
    display:block;
    font-size:14px;
    color:#000000;
    margin-left:50px;
}