#ItemListingMoreDetailsPrompt{
    display:block;
    
}

.ItemListingMoreDetails-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid #C4C4C4;
}

.ItemListingMoreDetails-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.ItemListingMoreDetails-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.ItemListingMoreDetails-Body{
    display:block;
    text-align: center;
    background-color: #FFFFFF;
    padding:30px;
}

.ItemListingMoreDetails-ValueLabel{
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    color: #000000;
}

.ItemListingMoreDetails-Value{
    display: block;
    font-size: 14px;
    text-align: left;
    color: #000000;
}

.ItemListingMoreDetails-ImageContainer {
    display: block;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #C4C4C4
}

.ItemListingMoreDetails-ImageHolder {
    display: block;
    padding: 10px;
    background: #373737;
}

.ItemListingMoreDetails-Body img {
    max-width: 100%;
    max-height: 250px;
}

.ItemListingMoreDetails-NoImage {
    display:block;
    padding:30px;
    font-size: 20px;
    font-weight: bold;
    text-align:center;
    color: white;
}

.ItemListingMoreDetails-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.ItemListingMoreDetails-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.ItemListingMoreDetails-CloseButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.ItemListingMoreDetails-CloseButtonDisabled{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}
