#BusinessInformationFormVendorContainer {
    position: relative;
    overflow: auto;
  }
  #BusinessInformationFormVendor {
    display: block;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    width: 600px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  
  .BusinessInformationFormVendor-Header {
    display: block;
    padding: 30px;
    background-color: #f4f4f4;
    border-radius: 20px 20px 0px 0px;
  }
  
  .BusinessInformationFormVendor-Contents {
    display: block;
    padding: 30px;
  }
  
  #BusinessInformationFormVendor-Description {
    font-size: 14px;
    display: block;
    text-align: left;
    color: #000000;
    margin-bottom: 10px;
  }
  
  #BusinessInformationFormVendor-Pretitle {
    font-size: 14px;
    font-weight: bold;
    display: block;
    text-align: left;
    color: #666666;
  }
  
  #BusinessInformationFormVendor-Title {
    font-size: 20px;
    font-weight: bold;
    display: block;
    text-align: left;
    color: #000000;
  }
  
  #BusinessInformationFormVendor-Subtitle {
    font-size: 14px;
    display: block;
    text-align: left;
    color: #666666;
  }
  
  #BusinessInformationFormVendor-FilterRow {
    display: block;
    margin-bottom: 30px;
  }
  
  #BusinessInformationFormVendor-FilterRow::after {
    display: table;
    content: "";
    clear: both;
  }
  
  .BusinessInformationFormVendor-InputHolderSmall {
    display: block;
    width: 100px;
    float: left;
  }
  
  .BusinessInformationFormVendor-InputHolderMedium {
    display: block;
    width: 205px;
    float: left;
    margin-left: 15px;
  }
  
  #BusinessInformationFormVendor-InputLabel {
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-bottom:15px;
    text-align: left;
    color: #000000;
  }

  #BusinessInformationFormVendor-InputLabelSmall {
    font-size: 12px;
    font-weight: bold;
    display: block;
    text-align: left;
    color: #000000;
  }

  #BusinessInformationFormVendor-InputLabelExtraSmall {
    font-size: 12px;
    font-weight: bold;
    display: block;
    text-align: left;
    color: #000000;
  }
  
  #BusinessInformationFormVendor-InputSubLabel{
    display: block;
    font-size: 11px;
    font-weight: bold;
    color:#EAA80A;
  }

#BusinessInformationFormVendor-HasLogistics{
    margin-right:10px;
}

#BusinessInformationFormVendor-HasNoLogistics{
  margin-right:10px;
}

#BusinessInformationFormVendor-RadioInputLabelLeft {
    display:inline-block;
    padding:5px;
    margin-right:50px;
    box-sizing: border-box;
    font-size: 14px;
    color: #000000; 
    cursor: pointer;
}

  #BusinessInformationFormVendor-TextInput {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
    background-color: #ffffff;
  }

  #BusinessInformationFormVendor-PaymentMethods {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    resize: none;
  }
  
  

  #BusinessInformationFormVendor-TextInputError {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
  }
  #BusinessInformationFormVendor-PaymentMethodsError {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
    resize: none;
  }
  
  #BusinessInformationFormVendor-TextError {
    display: block;
    margin-bottom: 10px;
    font-size: 10px;
    color: #ff5a26;
  }
  
  .BusinessInformationFormVendor-ButtonsLayout {
    display: block;
    border-top: 1px solid #c4c4c4;
  }
  
  .BusinessInformationFormVendor-ButtonsLayout::after {
    display: table;
    content: "";
    clear: both;
  }
  
  #BusinessInformationFormVendor-BackButton {
    display: block;
    float: left;
    margin-top: 50px;
    text-align: center;
    margin: 30px;
    padding: 10px 30px 10px 30px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
  }
  
  #BusinessInformationFormVendor-SaveButton {
    display: block;
    float: right;
    margin-top: 50px;
    text-align: center;
    margin: 30px;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    background-color: #1877f2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }

  #BusinessInformationFormVendor-BackButtonDisabled {
    display: block;
    float: left;
    margin-top: 50px;
    text-align: center;
    margin: 30px;
    padding: 10px 30px 10px 30px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }
  
  #BusinessInformationFormVendor-SaveButtonDisabled {
    display: block;
    float: right;
    margin-top: 50px;
    text-align: center;
    margin: 30px;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }
  
  .BusinessInformationFormVendor-AutoComplete {
    width: 100%;
    border: 1px solid #dddddd;
  }
  
  #Hidden {
    visibility: hidden;
  }
  #Gone {
    display: none;
  }

  #BusinessInformationFormVendor-Hint{
    display: block;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
  }

  #BusinessInformationFormVendor-TierDescription{
    display:block;
    padding:15px;
    background-color:#EBF5FF;
    text-align: left;
    font-size: 14px;
    color: #1B91FF;
  }

