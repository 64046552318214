#RequestsTableContainer {
  margin: 20px;
  background-color: #fdfdfd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
}

#RequestsTableContainer-HeaderRow {
  display: block;
  text-align: left;
  width: 100%;
  overflow: none;
}

#RequestsTableContainer-HeaderRow::after {
  display: table;
  content: "";
  clear: both;
}

.RequestsTableContainer-HeaderTitle {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.RequestsTableContainer-InputHolderSmall {
  display: block;
  width: 100px;
  float: left;
  margin-left: 15px;
}

.RequestsTableContainer-InputHolderMedium {
  display: block;
  width: 205px;
  float: left;
  margin-left: 15px;
}

.RequestsTableContainer-HeaderSectionTop {
  display: block;
  padding: 30px 30px 15px 30px;
  background-color: #f4f4f4;
}

.RequestsTableContainer-HeaderSectionBottom {
  display: block;
  margin: 15px 30px 0px 30px;
}

#RequestsTableSearch-InputLabelSmall {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  text-align: left;
  margin-bottom: 5px;
}

#RequestsTableSearch-SearchTextInput {
  font-size: 12px;
  text-align: left;
  color: #000000;
  width: 500px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.RequestsTableContainer-TableFilters {
  display: block;
  width: 365px;
  height: 35px;
  overflow: hidden;
  border: 1px solid #1b91ff;
  border-radius: 5px;
}

.RequestsTableContainer-TableFilters::after {
  display: table;
  content: "";
  clear: both;
}

.RequestsTableContainer-TableFiltersActiveColumn {
  display: block;
  float: left;
  width: 33.33%;
  height: 100%;
  background-color: #1b91ff;
  text-align: center;
  line-height: 35px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}

.RequestsTableContainer-TableFiltersInactiveColumn {
  display: block;
  float: left;
  width: 33.33%;
  height: 100%;
  background-color: #ffffff;
  text-align: center;
  line-height: 35px;
  font-size: 12px;
  font-weight: bold;
  color: #1b91ff;
  cursor: pointer;
}

.RequestsTableContainer-ItemCountLabel {
  display: block;
  padding: 15px 30px 0px 30px;
  float: left;
  font-size: 12px;
  color: #000000;
}

#RequestsTableContainer-Body {
  padding: 30px;
  text-align: center;
  height: 670px;
  overflow: auto;
}

#RequestsTable {
  border-collapse: collapse;
  width: 100%;
  border: 0px;
  border: 1px solid #dddddd;
}

#RequestsTable tr:first-child {
  background-color: #ececec;
}

#RequestsTable tr:nth-child(even) {
  background-color: #f6f6f6;
}

#RequestsTable .thRow {
  padding: 10px 15px 10px 15px;
  border-right: 1px solid #dddddd;
}
#RequestsTable .thRowLast {
  padding: 10px 15px 10px 15px;
}

#RequestsTable th {
  border-bottom: 1px solid #dddddd;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #666666;
}

#RequestsTable td {
  border: 0px;
  padding: 15px 15px 5px 15px;
  font-size: 12px;
  text-align: left;
  color: #000000;
}

#RequestsTable td:last-child {
  border: 0px;
  padding: 0px 15px 0px 15px;
  font-size: 12px;
  text-align: left;
  color: #000000;
}

.RequestsTable-ProgressValue {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}

#RequestsTable-ProgressBar {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #1b91ff;
}
#RequestsTable-ProgressBarComplete {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #8cd867;
}
#RequestsTable-ProgressBar::-webkit-progress-bar {
  background-color: #d7d7d7;
}
#RequestsTable-ProgressBar::-webkit-progress-value {
  background: #1b91ff;
}
#RequestsTable-ProgressBarComplete::-webkit-progress-value {
  background: #8cd867;
}
#RequestsTable-ProgressBar::-moz-progress-bar {
  background: #d7d7d7;
}
#RequestsTable-ProgressBar::-moz-progress-value {
  background: #1b91ff;
}

.RequestsTable-ActionButton {
  margin: 10px;
  display: inline-block;
  width: 115px;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
  background-color: #1b91ff;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.RequestsTable-EditActionButton {
  margin: 10px;
  display: inline-block;
  width: 70px;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
  background-color: #1b91ff;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.RequestsTable-EditDisabledButton {
  margin: 10px;
  display: inline-block;
  width: 70px;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
  background-color: #A9A9A9;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.RequestsTable-ActionButton-DonateCash {
  margin: 0 10px 10px 10px;
  display: inline-block;
  width: 185px;
  padding: 10px 0px 10px 0px;
  border-radius: 5px;
  background-color: #1b91ff;
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  color: white;
  cursor: pointer;
}
.RequestsTableContainer-HeaderSectionTop-Right {
  float: right;
}
.RequestsTable-ActionButton-Download {
  margin: 20px 0px 20px 30px;
  display: inline-block;
  width: 260px;
  padding: 10px;
  border-radius: 5px;
  background-color: #1b91ff;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.RequestsTable-ActionButton-Icon {
  padding-right: 5px;
}

.RequestsTable-ActionButtonCompleted {
  margin: 10px;
  display: inline-block;
  width: 115px;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
  background-color: #8cd867;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: white;
}

#RequestsTable-PlaceHolder {
  display: block;
  padding: 30px;
  height: 400px;
  text-align: left;
  font-size: 14px;
  color: #000000;
}

#RequestsTable-BlueLabel {
  color: #1b91ff;
  cursor: pointer;
}

#RequestsTable-Pagination {
  display: block;
  text-align: left;
}
#RequestsTable-Pagination::after {
  display: table;
  content: "";
  clear: both;
}
#RequestsTable-Pagination ul {
  display: block;
  padding: 0px;
  margin: 0px 30px 30px 30px;
}

#RequestsTable-Pagination .RequestsTable-ActiveLi {
  font-family: "Poppins", "Roboto", sans-serif;
  list-style-type: none;
  display: inline-block;
  min-width: 30px;
  border: 1px solid #1b91ff;
  background-color: #1b91ff;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  text-align: center;
  color: white;
  cursor: pointer;
}
#RequestsTable-Pagination .RequestsTable-InactiveLi {
  font-family: "Poppins", "Roboto", sans-serif;
  list-style-type: none;
  display: inline-block;
  min-width: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  text-align: center;
  color: #929292;
  cursor: pointer;
}

.RequestsTable-Loading {
  display: block;
  text-align: center;
  padding: 30px;
  font-size: 20px;
  font-weight: bold;
}

.Hidden {
  visibility: hidden;
}

.RequestsTable-ActionButton-ViewRequestImage {
  text-align: center;
  display: inline-block;
  margin: 5px 0px 5px 0px;
  color: #1877f2;
  font-size: 10px;
  cursor: pointer;
  font-weight: bold;
}

.RequestsTable-ActionButtonsLayout-Left {
  display: inline-block;
}
.RequestsTable-ActionButtonsLayout-Right {
  display: inline-block;
}

.RequestsTable-ActionButtonsLayout {
  display: block;
}

.RequestsTable-ActionButtonsLayout::after {
  display: table;
  content: "";
  clear: both;
}

#RequestsTableVendor {
  border-collapse: collapse;
  width: 100%;
  border: 0px;
  border: 1px solid #dddddd;
}

#RequestsTableVendor tr:first-child {
  background-color: #ececec;
}

#RequestsTableVendor tr:nth-child(even) {
  background-color: #f6f6f6;
}

#RequestsTableVendor .thRow {
  padding: 10px 15px 10px 15px;
  border-right: 1px solid #dddddd;
}
#RequestsTableVendor .thRowLast {
  padding: 10px 15px 10px 15px;
}

#RequestsTableVendor th {
  border-bottom: 1px solid #dddddd;
  border-left: 0px;
  border-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #666666;
}

#RequestsTableVendor td {
  border: 0px;
  padding: 15px 15px 15px 15px;
  font-size: 12px;
  text-align: left;
  color: #000000;
}

#RequestsTableVendor td:last-child {
  border: 0px;
  padding: 15px 15px 5px 15px;
  font-size: 12px;
  text-align: left;
  color: #000000;
}

#RequestsTableVendor td {
  border: 0px;
  padding: 15px 15px 15px 15px;
  font-size: 12px;
  text-align: left;
  color: #000000;
}

#RequestsTableVendor td:last-child {
  border: 0px;
  padding: 15px 15px 5px 15px;
  font-size: 12px;
  text-align: left;
  color: #000000;
}

#PledgersPage{
  display:block;
  margin-top:10px;
  height:100vh;
  background-color: transparent;
}

.PledgersPageButtonLayout{
  display:inline-block;
  margin-top:30px;
  margin-left:30px;
  cursor: pointer;
}

#PledgersPage-BackButton{
  display: inline;
  font-size: 14px;
  line-height: 25px;
  padding:10px;
  color: #1B91FF;
}

#PledgersPageContainer{
  display: block;
  margin: 15px 30px 0px 30px;
}