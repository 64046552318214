#PledgeForm{
    display:block;
}

.PledgeForm-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.PledgeForm-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.PledgeForm-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.PledgeForm-Body{
    display:block;
}

.PledgeForm-BodySection{
    display:block;
    padding: 30px;
    border-bottom: 1px solid #ECECEC;;
}

.PledgeForm-InputLayout{
    display:block; 
    margin-bottom: 15px;
}

.PledgeForm-InputLayoutLast{
    display:block;
    padding:15px;
}

.PledgeForm-InputLabelSmall {
    font-size: 12px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #000000;
}

.PledgeForm-InputValue {
    font-size: 14px;
    display:block;
    text-align:left;
    color: #000000;
}

.PledgeForm-Notice {
    display: block;
    padding: 15px;
    background-color: #ebf5ff;
    font-size: 14px;
    text-align: left;
    word-wrap: break-word;
    color: #1b91ff;
}

.PledgeForm-TextInput {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -moz-appearance: textfield;
}

.PledgeForm-TextInput-Error {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
    -moz-appearance: textfield;
}

.PledgeForm-NumberInput {
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -webkit-appearance: none; 
    margin-bottom: 10px; 
    -moz-appearance:textfield;
}
.PledgeForm-NumberInput::-webkit-inner-spin-button,
.PledgeForm-NumberInput::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin-bottom: 10px; 
}

.PledgeForm-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.PledgeForm-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.PledgeForm-CancelButton{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}

.PledgeForm-SubmitButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}


.PledgeForm-CancelButtonDisabled{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.PledgeForm-SubmitButtonDisabled{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.PledgeForm-AutoComplete {
  width: 100%;
  margin-top: 30px;
}

.PledgeForm-AutoComplete ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  position: absolute;
  /* z-index: 1; */
  background: white;
  border: 1px solid #dddd;
  width: 540px;
}

.PledgeForm-AutoComplete li {
  padding: 10px 15px;
  cursor: pointer;
}

.PledgeForm-AutoComplete li:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, 0.2);
}
