#UserTypeContainer{
    display: block;
    width:600px;
    margin:auto;
    padding:45px;
}

#UserTypeNotice{
    display: block;
    padding:15px;
    width:600px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    margin-bottom:30px;
}

#UserTypeNotice::after{
    display:table;
    content:"";
    clear:both;
}

#UserTypeNotice-Left{
    display:block;
    width:80%;
    float:left;
}

#UserTypeNotice-Right{
    display:block;
    width:20%;
    float:right;
}

#UserTypeNotice-Title{
    display: block;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    margin-left:15px;
}

#UserTypeNotice-Description{
    display: block;
    text-align: left;
    font-size: 12px;
    color: #000000;
    margin-left:15px;
}

#UserTypeNotice-Button{
    margin:30px 30px 20px 0px;
    display:block;
    height:30px;
    width:100px;
    background-color: #1B91FF;
    border-radius: 5px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    font-weight: bold;
    color:#FFFFFF;
    cursor: pointer;
}

#UserTypeForm {
    height:350px;
    width:600px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
}
#UserTypeForm-Header{
    display:block;
    padding: 30px;
    background-color: #F4F4F4;
    border-radius: 20px 20px 0px 0px;
}
.UserTypeForm-Contents{
    display:block;
    padding: 30px;
}

#UserTypeForm-Description {
    font-size: 14px;
    display:block;
    text-align:left;
    color: #000000;
    margin-bottom:20px;
}

#UserTypeForm-InputLayout{
    display: block;
    margin-bottom: 3%;
}

#UserTypeForm-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

#UserTypeForm-InputLayoutLeft{
    display:block;
    width:50%;
    float:left;
}

#UserTypeForm-InputLayoutRight{
    display:block;
    width:50%;
    float:right;
}

#UserTypeForm-RadioInputLabelLeft {
    display:block;
    padding:10px;
    margin-right:10px;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    color: #000000; 
    cursor: pointer;
}

#UserTypeForm-RadioInputLabelLeft:hover {
    border: 3px solid #1B91FF;
    color:#1B91FF;
}

#UserTypeForm-RadioInputLabelRight{
    display:block;
    padding:10px;
    margin-left:10px;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    color: #000000;    
    cursor: pointer;
}

#UserTypeForm-RadioInputLabelRightDisabled{
    display:block;
    padding:10px;
    margin-left:10px;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    color: #666666;    
}

#UserTypeForm-RadioInputLabelRight:hover {
    border: 3px solid #1B91FF;
    color:#1B91FF;
}

#NeedsHelp{
    margin-right:20px;
}

#WantsToHelp{
    margin-right:20px;
}

#WantsToSupply{
    margin-right:20px;
}

#DonateCash{
    margin-right:20px;
}


#UserTypeForm-InputLabel {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color:#000000;
}

#UserTypeForm-InputLabelSmall {
    font-size: 16px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #000000;
    margin-top: 20px;
    margin-bottom:5px;
}

#UserTypeForm-Hidden{
    visibility: hidden;
    display: block;
    padding:15px;
    width:600px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    margin-bottom:30px;
}

#UserTypeForm-Hidden::after{
    display:table;
    content:"";
    clear:both;
}