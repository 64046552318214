#PledgerBanner{
    display:block;
    min-height:240px;
    padding: 60px 30px 60px 30px;
    background-color: #4FD2D6;
    background-image: url('../../../../assets/banner_community_requests.svg');
    background-position: right;
    background-position-x: 90%;
    background-repeat: no-repeat;
}
#PledgerBannerLeft{
    display: block;
    width: 50%;
}
#PledgerBannerTitle{
    display: block;
    font-size: 31px;
    font-weight: bold;
    color: #3B3E54;
    word-wrap: break-word;
}
#PledgerBannerDescription{
    display: block;
    font-size: 14px;
    color: #3B3E54;
    word-wrap: break-word;
}