#UserInformationFormContainer {
  position: relative;
  height: 100vh;
  overflow: auto;
}

#UserInformationForm {
  display: block;
  margin: auto;
  margin-top: 50px;
  width: 600px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  margin-bottom: 50px;
}

.UserInformationForm-Header {
  display: block;
  padding: 30px;
  background-color: #f4f4f4;
  border-radius: 20px 20px 0px 0px;
}

.DonationCash-Header {
  display: block;
  height: 250px;
  background-image: url("../../../../assets/banner_community_requests.svg");
  border-radius: 20px 20px 0px 0px;
}

.UserInformationForm-Contents {
  display: block;
  padding: 30px;
}

#UserInformationForm-Description {
  font-size: 14px;
  display: block;
  text-align: left;
  color: #000000;
  margin-bottom: 30px;
}

#UserInformationForm-Pretitle {
  font-size: 14px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #666666;
}

#UserInformationForm-Title {
  font-size: 20px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #000000;
}

#UserInformationForm-Subtitle {
  font-size: 14px;
  display: block;
  text-align: left;
  color: #666666;
}

#UserInformationForm-InputLabel {
  font-size: 16px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
}
#UserInformationForm-InputLabelSmall {
  font-size: 12px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
}

#UserInformationForm-InputLabelLarge {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  padding-bottom: 5px;
  color: #000000;
  margin-bottom: 5px;
}

.UserInformationForm-InputRow {
  display: block;
}

.UserInformationForm-InputRow::after {
  display: table;
  content: "";
  clear: both;
}

.UserInformationForm-LeftColumn {
  display: block;
  width: 50%;
  float: left;
}

.UserInformationForm-RightColumn {
  display: block;
  width: 50%;
  float: right;
}

.UserInformation-InputContainerLeft {
  display: block;
  margin-right: 10px;
}
.UserInformation-InputContainerRight {
  display: block;
  margin-left: 10px;
}

#UserInformationForm-Hint {
  display: block;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
}

#UserInformationForm-FileInput {
  font-size: 12px;
  text-align: left;
  color: #000000;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}

#UserInformationForm-TextInput {
  font-size: 12px;
  text-align: left;
  color: #000000;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}

#DonationCash-IntentionInput {
  font-size: 12px;
  text-align: left;
  color: #000000;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  overflow: auto;
}

#UserInformationForm-NumberInput {
  font-size: 12px;
  text-align: left;
  color: #000000;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

#UserInformationForm-NumberInput::-webkit-inner-spin-button,
#UserInformationForm-NumberInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin-bottom: 10px;
}

#UserInformationForm-TextError {
  float: left;
  display: block;
  margin-bottom: 10px;
  font-size: 10px;
  color: #ff5a26;
}

#UserInformationForm-FileInputError {
  font-size: 12px;
  text-align: left;
  color: #000000;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ff5a26;
  background-color: #ffffff;
}

#UserInformationForm-TextInputError {
  font-size: 12px;
  text-align: left;
  color: #000000;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ff5a26;
  background-color: #ffffff;
}

#UserInformationForm-NumberInputError {
  font-size: 12px;
  text-align: left;
  color: #000000;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ff5a26;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

#UserInformationForm-NumberInputError::-webkit-inner-spin-button,
#UserInformationForm-NumberInputError::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin-bottom: 10px;
}

#UserInformationForm-VerificationSection {
  display: block;
  border-top: 1px solid #c4c4c4;
  margin-top: 15px;
  padding-top: 15px;
}

.UserInformationForm-ButtonsLayout {
  display: block;
  border-top: 1px solid #c4c4c4;
}
.UserInformationForm-ButtonsLayout::after {
  display: table;
  content: "";
  clear: both;
}

#UserInformationForm-BackButton {
  display: block;
  float: left;
  margin-top: 50px;
  text-align: center;
  margin: 30px;
  padding: 10px 30px 10px 30px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}

#UserInformationForm-SaveButton {
  display: block;
  float: right;
  margin-top: 50px;
  text-align: center;
  margin: 30px;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

#UserInformationForm-BackButtonDisabled {
  display: block;
  float: left;
  margin-top: 50px;
  text-align: center;
  margin: 30px;
  padding: 10px 30px 10px 30px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

#UserInformationForm-SaveButtonDisabled {
  display: block;
  float: right;
  margin-top: 50px;
  text-align: center;
  margin: 30px;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

.UserInformationForm-AutoComplete {
  width: 100%;
}

.UserInformationForm-AutoComplete ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  position: absolute;
  /* z-index: 1; */
  background: white;
  border: 1px solid #dddd;
  width: 540px;
}

.UserInformationForm-AutoComplete li {
  padding: 10px 15px;
  cursor: pointer;
}

.UserInformationForm-AutoComplete li:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, 0.2);
}

#Hidden {
  visibility: hidden;
}

#Gone {
  display: none;
}
