#DonorHome{
    position: relative;
    height: 100vh;
    overflow: auto;
}

#DonorHomeContainer{
    margin: 100px auto 30px auto;
    width:600px;
    border-radius: 25px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
}

.DonorHome-Header{
    display:block;
    height: 250px;
    background-image : url('../../../../assets/banner_community_requests.svg');
    border-radius: 20px 20px 0px 0px;
}

.DonorHome-HeaderTitle {
    font-size: 21px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:30px;
}

.DonorHome-Body{
    display:block;
    padding:30px;
}

.DonorHome-InputLayout{
    display:block;
}

.DonorHome-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

.DonorHome-InputLayout-Left{
    display:block;
    width:50%;
    float:left;
}

.DonorHome-InputLeft{
    display:block;
    margin-right:15px;
}

.DonorHome-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

.DoneHome-HighLightInfo {
    font-weight: 700;
}

p.DonorHome-ParagraphStyle{
    word-wrap: break-word;
}