#PageHeader {
    position: relative;
    font-weight: bold;
    display:block;
    width:100%;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}

#PageHeader::after{
    display:table;
    content:"";
    clear:both;
}

.PageHeader-Left {
    display:block;
    width:30%;
    float:left;
}

.PageHeader-Left img {
    cursor: pointer;
}

.PageHeader-Right {
    display:block;
    width:70%;
    float:right;
}

.PageHeader-Left img{
    height:60px;
    width:200px;
    margin:15px 10px 10px 30px;
    display:block;
    float:left;
}

.PageHeader-Title {
    font-size: 24px;
    font-weight: bold;
    color:#000000;
    display:block;
    float: left;
    padding:20px;
}

.MenuItem {
    display:block;
    float:right;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.MenuItemActive {
    padding:30px;
    margin: 0px;
    display: block;
    color: #1B91FF;
    text-align: center;
    text-decoration: none;
    border-bottom:4px solid #1B91FF;
}

.MenuItemInactive {
    padding:30px;
    margin: 0px;
    display: block;
    color: #000000;
    text-align: center;
    text-decoration: none;
  }

.MenuItemInactive:hover, .MenuItemActive:hover {
    color: #1B91FF;
}

.MenuItemInactive a, .MenuItemActive a:hover {
    color: #000000;
}
