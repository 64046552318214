#LoginPage {
  margin: auto;
  margin-top: 50px;
  display: block;
  width: 420px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

#LoginPageContents {
  display: block;
  padding: 30px;
}

#LoginPageContentsFooter {
  border-top: 1px solid #dbdbdb;
  padding: 15px;
}

#LoginPageContentsFooter-WhatToExpect {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #1b91ff;
  cursor: pointer;
}

#LoginPage-Logo {
  width: 250px;
  height: 100px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

#LoginPage-Title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #000000;
  border-top: 1px solid #dbdbdb;
}

#LoginPageContents-Description {
  font-size: 14px;
  display: block;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
  word-wrap: break-word;
}

#FacebookIcon {
  height: 20px;
  width: 12px;
  margin: 5px 10px 5px 5px;
}

#LoginPageContents-FBLoginButton {
  height: 43px;
  margin-top: 50px;
  text-align: center;
  display: block;
  padding: 7px;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

#LoginPageContents-LoginButtonDisabled {
  height: 43px;
  margin-top: 50px;
  text-align: center;
  display: block;
  padding: 12px;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

#LoginPageContents-LoginButtonLoading {
  margin: auto;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#LoginPageContents-DataPrivacy {
  text-align: center;
  font-size: 10px;
  color: #a6a6a6;
  line-height: 1.4;
  margin-top: 30px;
}

#LoginPageContents-DataPrivacy a {
  text-decoration: underline;
  color: #a6a6a6;
}
