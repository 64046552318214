#DonorProfile{
    position: relative;
    height: 100vh;
    overflow: auto;
}

#DonorProfileContainer{
    margin: 100px auto 30px auto;
    width:600px;
    border-radius: 25px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
}

.DonorProfile-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.DonorProfile-PreHeaderTitle {
    font-size: 16px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.DonorProfile-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:30px;
}

.DonorProfile-Body{
    display:block;
    padding:30px;
}

.DonorProfile-InputLabelSmall {
    font-size: 14px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:5px;
}

.DonorProfile-InputLabelExtraSmall {
    font-size: 12px;
    font-weight: 700;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:5px;
}

.DonorProfile-InputValue{
    font-size: 14px;
    text-align: left;
    color: #000000;
    margin-bottom:30px;
}

.DonorProfile-InputValueBlue{
    font-size: 14px;
    text-align: left;
    color: #1B91FF;
    margin-bottom:30px;
}

.DonorProfile-InputLayout{
    display:block;
}

.DonorProfile-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

.DonorProfile-InputLayout-Left{
    display:block;
    width:50%;
    float:left;
}

.DonorProfile-InputLeft{
    display:block;
    margin-right:15px;
}

.DonorProfile-InputLayout-Right{
    display:block;
    width:50%;
    float:right;
}

.DonorProfile-InputRight{
    display:block;
    margin-left:15px;
}
.DonorProfile-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

hr.DonorProfile-PaddingMargin{
    margin-bottom: 30px;
}

p.DonorProfile-ParagraphStyle{
    color: #212121;
    word-wrap: break-word;
}

.DonorProfile-ButtonsLayout {
    display: block;
}

#DonorProfile-SaveButton {
    display: block;
    text-align: center;
    width:30%;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    background-color: #1877f2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    margin: 20px auto;
    float: right;
}


#SignoutModalButton {
    display: block;
    text-align: center;
    width:30%;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    background-color: #1877f2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    margin-left: auto;
    float: right;
    border: solid #1877f2 1px;
    outline: none;
}

#SignoutCancelModalButton {
    display: block;
    text-align: center;
    width:30%;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    color: #777777;
    border: solid #a8a8a8 1px;
    cursor: pointer;
    margin-right: auto;
    float: left;
    outline: none;
}

.action-modal{
    border-top: solid #e2e2e2 1px;
    padding-top: 20px;
    padding: 20px 20px 0 20px;
    margin-left: -20px;
    margin-right: -20px;
}