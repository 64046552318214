#EditPledgedQtyForm {
    display: block;
}
  
.EditPledgedQtyForm-Header {
  display: block;
  padding: 30px;
  background-color: #f4f4f4;
  border-radius: 20px 20px 0px 0px;
}

.EditPledgedQtyForm-PreHeaderTitle {
  font-size: 14px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #666666;
  margin-bottom: 5px;
}

.EditPledgedQtyForm-HeaderTitle {
  font-size: 20px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #212121;
}

.EditPledgedQtyForm-Body {
  display: block;
  padding: 30px;
  text-align: center;
  background-color: #ffffff;
}

.EditPledgedQtyForm-InputLabelSmall {
  font-size: 12px;
  font-weight: 600;
  display: block;
  text-align: left;
  color: #212121;
  margin-bottom: 5px;
}

.EditPledgedQtyForm-InputLabelSmall-Error {
  font-size: 12px;
  display: block;
  text-align: left;
  color: #ff5a26;
  margin-bottom: 5px;
  margin-top: 5px;
}

.EditPledgedQtyForm-Footer {
  display: block;
  padding: 30px;
  border-top: 1px solid #c4c4c4;
}

.EditPledgedQtyForm-Footer::after {
  display: table;
  content: "";
  clear: both;
}

.EditPledgedQtyForm-SkipButton {
  display: block;
  float: left;
  padding: 5px 25px 5px 25px;
  text-align: center;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}

.EditPledgedQtyForm-SkipButtonDisabled {
  display: block;
  float: left;
  padding: 5px 25px 5px 25px;
  text-align: center;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

.EditPledgedQtyForm-SubmitButton {
  display: block;
  float: right;
  padding: 5px 25px 5px 25px;
  text-align: center;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.EditPledgedQtyForm-SubmitButtonDisabled {
  display: block;
  float: right;
  padding: 5px 25px 5px 25px;
  text-align: center;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}

.EditPledgedQtyForm-AutoComplete {
  width: 100%;
  margin-top: 30px;
}

.EditPledgedQtyForm-AutoComplete ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  position: absolute;
  background: white;
  border: 1px solid #dddd;
  width: 540px;
}

.EditPledgedQtyForm-AutoComplete li {
  padding: 10px 15px;
  cursor: pointer;
}

.EditPledgedQtyForm-AutoComplete li:hover {
  text-decoration: underline;
  background-color: rgba(128, 128, 128, 0.2);
}

.EditPledgedQtyForm-NumberInput {
  font-size: 12px;
  text-align: left;
  color: #000000;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  -webkit-appearance: none; 
}
.EditPledgedQtyForm-NumberInput::-webkit-inner-spin-button,
.EditPledgedQtyForm-NumberInput::-webkit-outer-spin-button{
  -webkit-appearance: none; 
}