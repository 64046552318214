#GenericPrompt {
  display: block;
}

.GenericPrompt-Body {
  display: block;
  padding: 30px;
  text-align: center;
}

.GenericPrompt-Body img {
  width: 50px;
  height: 50px;
}

.cash-donation-success-body {
  display: block;
  padding: 60px 60px 25px 60px;
  text-align: center;
}

.cash-donation-success-body .cash-donation-modal-icons {
  width: 50px;
  height: 50px;
}

.cash-donation-success-logos-container {
  padding: 25px 40px;
  border-top: 1px solid #ececec;
}

.GenericPrompt-Title {
  display: block;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.GenericPrompt-Description {
  font-size: 14px;
  color: #000000;
}

.GenericPrompt-Footer {
  display: block;
  padding: 0px 30px 30px 30px;
}

.GenericPrompt-Footer::after {
  display: table;
  content: "";
  clear: both;
}

.GenericPrompt-CancelButton {
  display: block;
  float: left;
  padding: 5px 30px 5px 30px;
  text-align: center;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}

.GenericPrompt-SubmitButton {
  display: block;
  float: right;
  padding: 5px 30px 5px 30px;
  text-align: center;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.GenericPrompt-GotItButton {
  display: block;
  width: 100px;
  margin: auto;
  padding: 5px 30px 5px 30px;
  text-align: center;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.DonationCash-CloseButton {
  display: block;
  padding: 5px 30px 5px 30px;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  width: 100px;
  cursor: pointer;
  margin: 0 auto;
}

.DonationCash-Footer {
  display: block;
  padding: 30px;
  border-top: 1px solid #ececec;
}

.GenericPrompt-SubmitButton-Proceed {
  display: block;
  padding: 5px 30px 5px 30px;
  text-align: center;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.GenericPrompt-CancelButtonDisabled {
  display: block;
  float: left;
  padding: 5px 30px 5px 30px;
  text-align: center;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  cursor: pointer;
}

.GenericPrompt-SubmitButtonDisabled {
  display: block;
  float: right;
  padding: 5px 30px 5px 30px;
  text-align: center;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  cursor: pointer;
}

.AccountUpdate-Title {
  display: block;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.AccountUpdate-Body {
  display: block;
  padding: 20px;
  text-align: center;
}

.AccountUpdate-Body img {
  width: 50px;
  height: 50px;
}
