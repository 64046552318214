#PledgeeInformationPrompt{
    display:block;
}

.PledgeeInformation-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.PledgeeInformation-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.PledgeeInformation-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.PledgeeInformation-Body{
    display:block;
    padding:30px;
}

.PledgeeInformation-ShippingStatusLayout{
    display:block;
    padding-top: 20px;
    border-top: 1px solid #C4C4C4;
}

.PledgeeInformation-InputTitle {
    font-size: 16px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #000000;
    margin-bottom:10px;
}

.PledgeeInformation-InputLabelSmall {
    font-size: 12px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #000000;
    margin-bottom:5px;
}

.PledgeeInformation-InputValue{
    font-size: 14px;
    text-align: left;
    color: #000000;
    margin-bottom:15px;
}

.PledgeeInformation-InputValueBlue{
    font-size: 14px;
    text-align: left;
    color: #1B91FF;
    margin-bottom:30px;
}



.PledgeeInformation-InputLayout{
    display:block;
}

.PledgeeInformation-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

.PledgeeInformation-InputLayout-Left{
    display:block;
    width:50%;
    float:left;
}

.PledgeeInformation-InputLeft{
    display:block;
    margin-right:15px;
}

.PledgeeInformation-InputLayout-Right{
    display:block;
    width:50%;
    float:right;
}

.PledgeeInformation-InputRight{
    display:block;
    margin-left:15px;
}

#Packing{
    margin-right:10px;
}

#Shipping{
    margin-right:10px;
}

#ETA{
    margin-right:10px;
}

#PledgeeInformation-RadioInputLabelLeft {
    display:block;
    padding:5px;
    margin-right:10px;
    box-sizing: border-box;
    font-size: 14px;
    color: #000000; 
    cursor: pointer;
}

.PledgeeInformation-TextInput{
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -moz-appearance:textfield;
}

.PledgeeInformation-TextInputError{
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
    -moz-appearance:textfield;
}

.PledgeeInformation-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.PledgeeInformation-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.PledgeeInformation-CancelButton{
    display:block;
    float:right;
    width: 97px;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.PledgeeInformation-CancelButtonDisabled{
    display:block;
    float:right;
    width: 97px;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.PledgeeInformation-CancelButtonLoading{
    margin: auto;
    border: 3px solid #FFFFFF; 
    border-top: 3px solid transparent; 
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

.PledgeeInformation-TextError {
    font-size: 10px;
    display:block;
    text-align:left;
    color: #ff5a26;
    margin-top:5px;
}

.PledgeeInformation-Gone {
    display: none;
}