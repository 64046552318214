#AdminDatabaseContainer{
    margin: 20px;
    background-color: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

#AdminDatabaseContainer-HeaderRow{
    display:block;
    text-align: left;
    width:100%;
    padding:30px;
}
.AdminDatabaseContainer-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    color:#000000;
}

#AdminDatabaseContainer-FilterRow{
    display:block;
    padding:15px;
    background-color: #F7F7F7;
}

#AdminDatabaseContainer-FilterRow::after{
    display:table;
    content:"";
    clear:both;
}

#AdminDatabaseContainer-FilterRowLeft{
    display:block;
    width:50%;
    float:left;
}

#AdminDatabaseContainer-FilterRowRight{
    display:block;
    width:50%;
    float:right;
    text-align: right;
}

.AdminDatabaseContainer-InputHolderSmall{
    display: block;
    width:100px;
    float:left;
    margin-left:15px;
}

.AdminDatabaseContainer-InputHolderMedium{
    display: block;
    width:205px;
    float:left;
    margin-left:15px;
}

.AdminDatabaseContainer-InputHolderLarge{
    display: block;
    width:425px;
    float:right;
    margin-right:15px;
}

#RequestsTableSearch-InputLabelSmall{
    display:block;
    font-size:12px;
    font-weight: bold;
    color:#000000;
    text-align: left;
    margin-bottom:5px;
}

#RequestsTableSearch-TextInput{
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
}

#AdminDatabaseContainer-Body{
    padding:30px;
    text-align:center;
    max-height:70vh;
    overflow:auto;
}

#RequestsTable{
    border-collapse: collapse;
    width: 100%;
    border:0px;
    border: 1px solid #dddddd;
}

#RequestsTable tr:first-child {
    background-color: #ECECEC;;
}

#RequestsTable tr:nth-child(even) {
    background-color: #F6F6F6;;
}

#RequestsTable .thRow {
  padding: 10px 15px 10px 15px;
  border-right: 1px solid #dddddd;
}
#RequestsTable .thRowLast {
    padding: 10px 15px 10px 15px;
  }
  
#RequestsTable th {
    border-bottom: 1px solid #dddddd;
    border-left: 0px;
    border-right: 0px;
    padding-top:5px;
    padding-bottom:5px;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #666666;
}

.RequestsTable-ProgressValue{
    display:block;
    text-align: left;
    margin-bottom:5px;
}

#RequestsTable-ProgressBar{
    display:block;
    width:100%;
    height:4px;
    background-color:#1B91FF;
}
#RequestsTable-ProgressBarComplete{
    display:block;
    width:100%;
    height:4px;
    background-color: #8CD867;
}
#RequestsTable-ProgressBar::-webkit-progress-bar{
    background-color: #D7D7D7;
}
#RequestsTable-ProgressBar::-webkit-progress-value{
    background: #1B91FF;
}
#RequestsTable-ProgressBarComplete::-webkit-progress-value{
    background: #8CD867;
}
#RequestsTable-ProgressBar::-moz-progress-bar{
    background: #D7D7D7;
}
#RequestsTable-ProgressBar::-moz-progress-value{
    background: #1B91FF;
}
#RequestsTable-ProgressBarComplete::-webkit-progress-value{
    background: #8CD867;
}

.RequestsTable-ActionButton{
    margin: 10px;
    display:inline-block;
    width: 115px;
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
    background-color: #1B91FF;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.RequestsTable-ActionButtonCompleted{
    margin: 10px;
    display:inline-block;
    width: 115px;
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
    background-color: #8CD867;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: white;
}

.RequestsTable-Loading{
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}