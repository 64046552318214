.page-requests {
    position: relative;
    display:block;
    height:100vh;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-container {
    margin: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.page-row {
    padding-top: 30px;
}

