

.RejectVendorPrompt-Header{
    display:block;
    padding:30px;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid #C4C4C4;
}
.RejectVendorPrompt-Header::after{
    display:table;
    content:"";
    clear:both;
}

.RejectVendorPrompt-Header img{
    display:block;
    float:left;
    height:50px;
    width: 50px;
    margin:5px 15px 5px 0px;
}

.RejectVendorPrompt-HeaderLayout{
    display:block;
    float:left;
}

.RejectVendorPrompt-SubHeaderTitle {
    font-size: 14px;
    display:block;
    text-align:left;
    color: #000000;
    margin-bottom:5px;
}

.RejectVendorPrompt-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.RejectVendorPrompt-Body{
    display:block;
    padding:30px;
}
.RejectVendorPrompt-InputLabelSmall {
    font-size: 12px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:5px;
}

.RejectVendorPrompt-InputValue{
    font-size: 14px;
    text-align: left;
    color: #000000;
    margin-bottom:30px;
}

.RejectVendorPrompt-InputValueBlue{
    font-size: 14px;
    text-align: left;
    color: #1B91FF;
    margin-bottom:30px;
}


#RejectVendorPrompt-Reject{
    margin-right:10px;
}

#RejectVendorPrompt-Ban{
    margin-right:10px;
}

#RejectVendorPrompt-RadioInputLabelLeft {
    display:inline-block;
    padding:5px;
    margin-right:50px;
    box-sizing: border-box;
    font-size: 14px;
    color: #000000; 
    cursor: pointer;
}

#RejectVendorPrompt-ReasonInput {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    resize: none;
  }

  #RejectVendorPrompt-ReasonInputError {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
    resize: none;
  }

  .RejectVendorPrompt-ReasonInputErrorMessage {
    display: block;
    text-align: left;
    font-size: 10px;
    color: #ff5a26;
  }
  

.RejectVendorPrompt-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

.RejectVendorPrompt-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.RejectVendorPrompt-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.RejectVendorPrompt-CancelButton{
    display:block;
    float:left;
    width: 100px;
    height:30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
    color: #000000;
    cursor: pointer;
}

.RejectVendorPrompt-CancelButtonDisabled{
    display:block;
    float:left;
    width: 100px;
    height:30px;
    line-height: 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    color: #666666;
}


.RejectVendorPrompt-RejectButton{
    display:block;
    float:right;
    width: 100px;
    height:30px;
    line-height: 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #FF5A26;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.RejectVendorPrompt-RejectButtonDisabled{
    display:block;
    float:right;
    width: 100px;
    height:30px;
    line-height: 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.RejectVendorPrompt-RejectionCheckbox{
    display:block;
    padding:20px 30px 20px 30px;
}

#RejectVendorPrompt-Confirmation{
    margin-right:10px;
}

#RejectVendorPrompt-ConfirmationLabel{
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.RejectVendorPrompt-ReasonInputHolder{
    display:block;
    margin-top:15px;
}

.RejectVendorPrompt-Gone {
    display:none;
}
