#VendorInformationPrompt{
    display:block;
}

.VendorInformation-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.VendorInformation-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.VendorInformation-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.VendorInformation-Body{
    display:block;
    padding:30px;
}
.VendorInformation-InputLabelSmall {
    font-size: 12px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:5px;
}

.VendorInformation-InputValue{
    font-size: 14px;
    text-align: left;
    color: #000000;
    margin-bottom:30px;
}

.VendorInformation-InputValueBlue{
    font-size: 14px;
    text-align: left;
    color: #1B91FF;
    margin-bottom:30px;
}



.VendorInformation-InputLayout{
    display:block;
}

.VendorInformation-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

.VendorInformation-InputLayout-Left{
    display:block;
    width:50%;
    float:left;
}

.VendorInformation-InputLeft{
    display:block;
    margin-right:15px;
}

.VendorInformation-InputLayout-Right{
    display:block;
    width:50%;
    float:right;
}

.VendorInformation-InputRight{
    display:block;
    margin-left:15px;
}


.VendorInformation-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.VendorInformation-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.VendorInformation-CancelButton{
    display:block;
    float:right;
    padding: 5px 30px 5px 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}
