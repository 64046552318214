.PledgerDonateCash-Header {
  background-image: url("../../assets/Heading.svg");
  width: 100%;
  background-repeat: no-repeat;
  height: 300px;
}

.PledgerDonateCash-Body {
  padding: 30px;
}

.PledgerDonateCash-Title {
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  padding-bottom: 5px;
  color: #000000;
}

.PledgerDonateCash-Content {
  padding-bottom: 15px;
  color: #000000;
}

.border-bottom {
  padding-bottom: 15px;
  border-bottom: 1px solid #ececec;
  margin-bottom: 15px;
}

.PledgerDonateCash-ContentBr {
  padding-bottom: 20px;
  padding-top: 5px;
  color: #000000;
}

.PledgerDonateCash-InputLayout {
  border-top: 1px solid #c4c4c4;
  padding: 30px 0px 15px 0px;
}

.PledgerDonateCash-InputLayout-Title {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 5px;
  color: #000000;
}

.PledgerDonateCash-InputLayout-Error {
  font-size: 10px;
  line-height: 14px;
  color: #ff5a26;
  padding-top: 5px;
}

.PledgerDonateCash-Input {
  width: 540px;
  height: 37px;
  padding-left: 10px;
}

.PledgerDonateCash-Input-Error {
  width: 540px;
  height: 37px;
  padding-left: 10px;
  border: 1px solid #ff5a26;
}

.PledgerDonateCash-Footer {
  width: 599px;
  height: 97px;
  padding: 30px 30px 37px 30px;
  border-top: 1px solid #c4c4c4;
}

.PledgerDonateCash-Footer-Cancel {
  padding: 5px 28px;
  background: #ffffff;
  font-weight: 700;
  color: #000000;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
  float: left;
}

.PledgerDonateCash-Footer-Submit {
  padding: 5px 0;
  background: #1b91ff;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  width: 100px;
  float: right;
}

.PledgerDonateCash-Footer-Submit-Disable {
  padding: 5px 0;
  background: lightgrey;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  width: 100px;
  float: right;
}
