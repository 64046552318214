#UpdateAccountForm{
    display:block;
}

.UpdateAccount-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.UpdateAccount-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.UpdateAccount-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.UpdateAccount-Body{
    display:block;
    padding:30px;
}

.UpdateAccount-InputLayout{
    display:block;
    margin-top:10px;
}

.UpdateAccount-InputLabelSmall {
    font-size: 12px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:5px;
}

#UpdateAccount-TextInput{
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -moz-appearance:textfield;
}

#UpdateAccount-NumberInput {
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -webkit-appearance: none; 
}
.UpdateAccount-NumberInput::-webkit-inner-spin-button,
.UpdateAccount-NumberInput::-webkit-outer-spin-button{
    -webkit-appearance: none; 
}

#UpdateAccount-NumberInputError {
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
  
  #UpdateAccount-NumberInputError::-webkit-inner-spin-button,
  #UpdateAccount-NumberInputError::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin-bottom: 10px;
  }


.UpdateAccount-InputLayout{
    display:block;
}

.UpdateAccount-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

.UpdateAccount-InputLayout-Left{
    display:block;
    width:50%;
    float:left;
}

.UpdateAccount-InputLeft{
    display:block;
    margin-right:15px;
}

.UpdateAccount-InputLayout-Right{
    display:block;
    width:50%;
    float:right;
}

.UpdateAccount-InputRight{
    display:block;
    margin-left:15px;
}

.UpdateAccount-ErrorText{
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #FF5A26;
}

#UpdateAccount-TextError {
    float: left;
    margin-bottom: 10px;
    font-size: 10px;
    color: #ff5a26;
}

#UpdateAccount-TextInputError {
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
  }


.UpdateAccount-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.UpdateAccount-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.UpdateAccount-CancelButton{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}

.UpdateAccount-SubmitButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.UpdateAccount-CancelButtonDisabled{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.UpdateAccount-SubmitButtonDisabled{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.Gone {
    display:none;
}

.UpdateAccount-AutoComplete {
    width: 100%;
}

.UpdateAccount-AutoComplete ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    position: absolute;
    /* z-index: 1; */
    background: white;
    border: 1px solid #dddd;
    width: 540px;
}
  
.UpdateAccount-AutoComplete li {
    padding: 10px 15px;
    cursor: pointer;
}

.UpdateAccount-AutoComplete li:hover {
    text-decoration: underline;
    background-color: rgba(128, 128, 128, 0.2);
}

#Hidden {
    display: block;
    margin-bottom: 10px;
    visibility: hidden;
}

#UpdateAccount-AddressInput {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    resize: none;
}

#UpdateAccount-AddressInputError {
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ff5a26;
    background-color: #ffffff;
    resize: none;
}

#UpdateAccount-HasSubscribed{
    margin-right:10px;
}

#UpdateAccount-CheckboxLabelLeft {
    display:inline-block;
    padding:5px;
    margin-right:50px;
    box-sizing: border-box;
    font-size: 14px;
    color: #000000; 
    cursor: pointer;
}

.UpdateAccount-InputLayout-Bottom-Label {
    font-style: normal;
    font-weight: bold;
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: solid 2px #CCC;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color:#1877f2;
  border: solid 2px #1877f2;
}

.container:hover {
  color: #1877f2;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: transparent;
  border: solid 2px #1877f2;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 2px;
	left: 2px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
  background: #1877f2;
}