#UserInformationFormVendorContainer{
    position: relative;
    height:94vh;
    overflow: auto;
}
#UserInformationFormVendor {
    display:block;
    margin:auto;
    margin-top:50px;
    width:600px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
}

.UserInformationFormVendor-Header{
    display:block;
    padding: 30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.UserInformationFormVendor-Contents{
    display:block;
    padding: 30px;
}

#UserInformationFormVendor-Description {
    font-size: 14px;
    display:block;
    text-align:left;
    color: #000000;
    margin-bottom:10px;
}

#UserInformationFormVendor-Pretitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
}

#UserInformationFormVendor-Title {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #000000;
}

#UserInformationFormVendor-Subtitle {
    font-size: 14px;
    display:block;
    text-align:left;
    color: #666666;
}

#UserInformationFormVendor-InputLabel {
    font-size: 16px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #000000;
    margin-bottom:5px;
}

#UserInformationFormVendor-InputLabelSmall {
    font-size: 12px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #000000;
    margin-bottom:5px;
}

.UserInformationFormVendor-InputRow{
    display: block;
}

.UserInformationFormVendor-InputRow::after{
    display: table;
    content: "";
    clear: both;
}

.UserInformationFormVendor-LeftColumn{
    display: block;
    width:50%;
    float:left;
}

.UserInformationFormVendor-RightColumn{
    display: block;
    width:50%;
    float:right;
}

.UserInformation-InputContainerLeft{
    display:block;
    margin-right:10px;
}
.UserInformation-InputContainerRight{
    display:block;
    margin-left:10px;
}

#UserInformationFormVendor-Hint{
    display: block;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
}

#UserInformationFormVendor-FileInput{
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
}

#UserInformationFormVendor-TextInput{
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
}

#UserInformationFormVendor-NumberInput {
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -webkit-appearance: none; 
    -moz-appearance:textfield;
}

#UserInformationFormVendor-NumberInput::-webkit-inner-spin-button,
#UserInformationFormVendor-NumberInput::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin-bottom: 10px; 
}

#UserInformationFormVendor-TextError{
    float:left;
    display:block;
    margin-bottom:10px;
    font-size: 10px;
    color:#FF5A26;
}

#UserInformationFormVendor-FileInputError{
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #FF5A26;
    background-color: #ffffff;
}

#UserInformationFormVendor-TextInputError {
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #FF5A26;
    background-color: #ffffff;
}

#UserInformationFormVendor-NumberInputError {
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #FF5A26;
    background-color: #ffffff;
    -webkit-appearance: none; 
    -moz-appearance:textfield;
}

#UserInformationFormVendor-NumberInputError::-webkit-inner-spin-button,
#UserInformationFormVendor-NumberInputError::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin-bottom: 10px; 
}



#UserInformationFormVendor-VerificationSection{
    display:block;
    border-top: 1px solid #C4C4C4;
    margin-top:15px;
    padding-top:15px;
}

.UserInformationFormVendor-ButtonsLayout{
    display:block;
    border-top:1px solid #C4C4C4;
}
.UserInformationFormVendor-ButtonsLayout::after{
    display:table;
    content:"";
    clear: both;
}

#UserInformationFormVendor-BackButton{
    display:block;
    float: left;
    margin-top:50px;
    text-align: center;
    margin:30px;
    padding: 10px 30px 10px 30px;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}


#UserInformationFormVendor-SaveButton{
    display:block;
    float: right;
    margin-top:50px;
    text-align: center;
    margin:30px;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

#UserInformationFormVendor-BackButtonDisabled{
    display:block;
    float: left;
    margin-top:50px;
    text-align: center;
    margin:30px;
    padding: 10px 30px 10px 30px;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

#UserInformationFormVendor-SaveButtonDisabled{
    display:block;
    float: right;
    margin-top:50px;
    text-align: center;
    margin:30px;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

#Hidden {
    visibility: hidden;
}

#Gone {
    display: none;
}