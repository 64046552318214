#EdmMailContainer{
  margin: 20px;
  background-color: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

#EdmAnnouncementLabel
{
  padding:5px 10px 5px 10px;
  margin:10px 5px 10px 5px;
}

#EdmMailContainer-Banner{
    position: absolute;
    width: 600px;
    height: 220px;
    left: 0px;
    top: 0px;
}

#EdmMailContainer-HeaderDiv{
    position: absolute;
    width: 540px;
    height: 290px;
    left: 30px;
    top: 265px;
}

#EdmMailContainer-HeaderText{
    position: absolute;
    width: 540px;
    height: 50px;
    left: 30px;
    top: 265px;

    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    text-align: center;

    color: #FB7305;

}

#EdmMailContainer-SubHeaderText{
    position: absolute;
    width: 540px;
    height: 210px;
    left: 30px;
    top: 345px;
    
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    
    color: #545757;

}

#HighlightText
{
    color: #FB7305;
}

#EdmMailContainer-FulfilledItems{
    position: absolute;
    width: 540px;
    height: 290px;
    left: 30px;
    top: 265px;
}

#EdmMailContainer-FulfilledRectangle{
    position: absolute;
    width: 312px;
    height: 58px;
    left: 144px;
    top: 585px;

    background: #FB7305;
    border-radius: 50px;
}

#EdmMailContainer-FulfilledRectangleText{
position: absolute;
width: 222px;
height: 28px;
left: 189px;
top: 601px;

font-family: Arial;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 28px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}

#EdmMailContainer-ImageGroup{
    position: absolute;
width: 540px;
height: 252px;
left: 30px;
top: 688px;

}

#EdmMailContainer-Image1
{
    position: absolute;
    width: 172px;
    height: 120px;
    left: 30px;
    top: 688px;
}

#EdmMailContainer-Image2
{
    position: absolute;
width: 172px;
height: 120px;
left: 214px;
top: 688px;

}

#EdmMailContainer-Image3
{
    position: absolute;
width: 172px;
height: 120px;
left: 398px;
top: 688px;

}

#EdmMailContainer-Image4
{
    position: absolute;
width: 172px;
height: 120px;
left: 30px;
top: 820px;
}

#EdmMailContainer-Image5
{
    position: absolute;
width: 172px;
height: 120px;
left: 214px;
top: 820px;
}

#EdmMailContainer-Image6
{
    position: absolute;
width: 172px;
height: 120px;
left: 398px;
top: 820px;
}


#EdmMailContainer-HospitalDivCount
{
    position: absolute;
width: 540px;
height: 63px;
left: 30px;
top: 985px;

}

#EdmMailContainer-HospitalDivText
{
    position: absolute;
width: 540px;
height: 63px;
left: 30px;
top: 985px;

font-family: Arial;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-align: center;

color: #545757;
}

#EdmMailContainer-Hospital
{
position: absolute;
width: 600px;
height: 549px;
left: 0px;
top: 1093px;
}

#EdmMailContainer-HospitalText
{
    position: absolute;
    width: 540px;
    height: 42px;
    left: 30px;
    top: 1093px;
    
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    
    color: #545757;
}

#EdmMailContainer-HospitalRectangle
{
    position: absolute;
    width: 600px;
    height: 492px;
    left: 0px;
    top: 1150px;
    
    background: #EFFAFF;
}
#EdmMailContainer-HospitalList
{
    position: absolute;
    width: 540px;
    height: 432px;
    left: 30px;
    top: 1180px;
    
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    
    color: #565554;
}
#EdmMailContainer-Hangad
{
    position: absolute;
width: 540px;
height: 517px;
left: 30px;
top: 1687px;
}

#EdmMailContainer-HangadText
{
    position: absolute;
width: 540px;
height: 63px;
left: 30px;
top: 1687px;

font-family: Arial;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-align: center;

color: #575553;
}

#EdmMailContainer-HangadText2
{
    position: absolute;
    width: 540px;
    height: 294px;
    left: 30px;
    top: 1910px;
    
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    
    color: #575553;
}

.PledgersListTable-ActionButtonsLayout-Left{
    display: inline-block;
}
.PledgersListTable-ActionButton-EmailBlast{
    text-align: center;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:10px 5px 10px 5px;
    border: 1px solid #FB7305;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 10px;
    font-weight: bold;
    color: #FB7305;
    cursor: pointer;
}

.PledgersListTable-ActionButton-TestEmail{
  text-align: center;
  display:inline-block;
  padding:5px 10px 5px 10px;
  margin:10px 5px 10px 5px;
  border: 1px solid #1B91FF;
  border-radius: 5px;
  background-color: #1B91FF;
  font-size: 10px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
}

a { color: #545757; text-decoration: underline; }
body {
  margin: 0;
  padding: 0;
}

table, tr, td {
  vertical-align: top;
  border-collapse: collapse;
}

p, ul {
  margin: 0;
}

.ie-container table, .mso-container table {
  table-layout: fixed;
}

* {
  line-height: inherit;
}

a[x-apple-data-detectors=true] {
  color: inherit !important;
  text-decoration: none !important;
}

.ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div {
  line-height: 100%;
}

[owa] .email-row .email-col {
  display: table-cell;
  float: none !important;
  vertical-align: top;
}

.ie-container .email-col-100, .ie-container .email-row, [owa] .email-col-100, [owa] .email-row { width: 600px !important; }
.ie-container .email-col-17, [owa] .email-col-17 { width: 102.00000000000001px !important; }
.ie-container .email-col-25, [owa] .email-col-25 { width: 150px !important; }
.ie-container .email-col-33, [owa] .email-col-33 { width: 198px !important; }
.ie-container .email-col-50, [owa] .email-col-50 { width: 300px !important; }
.ie-container .email-col-67, [owa] .email-col-67 { width: 402px !important; }

@media only screen and (min-width: 620px) {
  .email-row { width: 600px !important; }
  .email-row .email-col { vertical-align: top; }
  .email-row .email-col-100 { width: 600px !important; }
  .email-row .email-col-67 { width: 402px !important; }
  .email-row .email-col-50 { width: 300px !important; }
  .email-row .email-col-33 { width: 198px !important; }
  .email-row .email-col-25 { width: 150px !important; }
  .email-row .email-col-17 { width: 102.00000000000001px !important; }
}

@media (max-width: 620px) {
  .email-row-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .email-row .email-col {
    min-width: 320px !important;
    max-width: 100% !important;
    display: block !important;
  }
  .email-row { width: calc(100% - 40px) !important; }
  .email-col { width: 100% !important; }
  .email-col > div { margin: 0 auto; }
  .no-stack .email-col { min-width: 0 !important; display: table-cell !important; }
  .no-stack .email-col-50 { width: 50% !important; }
  .no-stack .email-col-33 { width: 33% !important; }
  .no-stack .email-col-67 { width: 67% !important; }
  .no-stack .email-col-25 { width: 25% !important; }
  .no-stack .email-col-17 { width: 17% !important; }
}

@media (max-width: 480px) {
  .hide-mobile {
    display: none !important;
    max-height: 0px;
    overflow: hidden;
  }
}

@media (min-width: 980px) {
  .hide-desktop {
    display: none !important;
    max-height: none !important;
  }
}