
#FAQsContainer{
    display:block;
    margin: 20px;
    padding: 30px;
    background-color: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

#FAQsContainer-HeaderRow{
    display:block;
    text-align: left;
    width:100%;
}
.FAQsContainer-HeaderTitle {
    font-size: 24px;
    font-weight: bold;
    color:#000000;
    margin-bottom:30px;
}

#FAQsContainer-Body{
    display:block;
    width:100%;
    padding:30px;
    text-align:left;
    border: 5px solid #1B91FF;
    max-height:80vh;
    overflow:auto;
}

#FAQ-Title{
    display:block;
    font-size:16px;
    font-weight:bold;
    color:#000000;
}

#FAQ-Description{
    display:block;
    font-size:14px;
    color:#000000;
    margin-left:30px;
}