#AdminLoginPage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display:block;
    width:420px;
}

#AdminLoginPage-Logo{
    width: 300px;
    height: 90px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:15px;
}
#AdminLoginPageContents {
    display:block;
    padding: 30px;
    border-radius: 25px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
}

#AdminLoginPage-Title {
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    margin-bottom: 30px;
}

#AdminLoginPage-InputLabelSmall {
    font-size: 12px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #000000;
    margin-top: 20px;
    margin-bottom:5px;
}

#AdminLoginPage-TextInput{
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    margin-bottom:10px;
}

#AdminLoginPageContents-LoginButton{
    margin-top:50px;
    text-align: center;
    display:block;
    padding: 10px;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}