#AccreditorVerificationListContainer{
    text-align: center;
    margin: 20px;
    background: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
}

.AccreditorVerificationList-Header {
    display:block;
    padding:30px 30px 10px 30px;
    background-color:#F4F4F4;
}
.AccreditorVerificationList-Header::after {
    display:table;
    content:"";
    clear:both;
}

.AccreditorVerificationList-HeaderLeft {
    display:block;
    width:700px;
    float:left;
}

.AccreditorVerificationList-InputHolderLarge{
    display: block;
    width:425px;
    float:right;
    margin-right:15px;
}

.AccreditorVerificationList-HeaderTitle {
    display:block;
    font-size: 20px;
    font-weight: bold;
    color:#000000;
    text-align: left;
    padding-bottom:10px;
}

.AccreditorVerificationList-TableFilters{
    display:block;
    width:700px;
    height:35px;
    overflow: hidden;
    border: 1px solid #1B91FF;
    border-radius: 5px;
}

.AccreditorVerificationList-TableFilters::after{
    display:table;
    content:"";
    clear:both;
}

.AccreditorVerificationList-TableFiltersActiveColumn{
    display:block;
    float:left;
    width:20%;
    height:100%;
    background-color: #1B91FF;
    text-align: center;
    line-height: 35px;
    font-size: 12px;
    font-weight: bold;
    color:#FFFFFF;
    cursor: pointer;
}

.AccreditorVerificationList-TableFiltersInactiveColumn{
    display:block;
    float:left;
    width:20%;
    height:100%;
    background-color: #FFFFFF;
    text-align: center;
    line-height: 35px;
    font-size: 12px;
    font-weight: bold;
    border-left: 0.1px solid #1B91FF;
    border-right: 0.1px solid #1B91FF;
    color:#1B91FF;
    cursor: pointer;
}

.AccreditorVerificationList-ItemCountLabel{
    display: block;
    padding: 15px 30px 30px 0px;
    float:left;
    font-size: 12px;
    color: #000000;
}

.AccreditorVerificationList-Body {
    display:block;
    padding:10px 30px 30px 30px;
}

#AccreditorVerificationList{
    border-collapse: collapse;
    width: 100%;
    border:0px;
    border: 1px solid #dddddd;
}

.AccreditorVerificationList-ActionButtonsLayout{
    display:block;
}

.AccreditorVerificationList-ActionButtonsLayout::after{
    display:table;
    content:"";
    clear:both;
}

.AccreditorVerificationList-ActionButtonsLayout-Left{
    display: inline-block;
}
.AccreditorVerificationList-ActionButtonsLayout-Right{
    display: inline-block;
}

#AccreditorVerificationList-ProgressBar{
    display:block;
    width:100%;
    height:4px;
    background-color:#1B91FF;
}
#AccreditorVerificationList-ProgressBarComplete{
    display:block;
    width:100%;
    height:4px;
    background-color: #8CD867;
}
#AccreditorVerificationList-ProgressBar::-webkit-progress-bar{
    background-color: #D7D7D7;
}
#AccreditorVerificationList-ProgressBar::-webkit-progress-value{
    background: #1B91FF;
}
#AccreditorVerificationList-ProgressBarComplete::-webkit-progress-value{
    background: #8CD867;
}
#AccreditorVerificationList-ProgressBar::-moz-progress-bar{
    background: #D7D7D7;
}
#AccreditorVerificationList-ProgressBar::-moz-progress-value{
    background: #1B91FF;
}
#AccreditorVerificationList-ProgressBarComplete::-webkit-progress-value{
    background: #8CD867;
}

.AccreditorVerificationList-ProgressValue{
    display:block;
    text-align: left;
    margin-bottom:5px;
}

.AccreditorVerificationList-ActionButton-VerifyButton{
    text-align: center;
    width: 100px;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:10px 5px 10px 5px;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.AccreditorVerificationList-ActionButton-RejectButton{
    text-align: center;
    width: 100px;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:10px 5px 10px 5px;
    border-radius: 5px;
    background-color: transparent;
    font-size: 10px;
    font-weight: bold;
    color: #FF5A26;
    cursor: pointer;
}

.AccreditorVerificationList-ActionButton-VerificationInfoButton{
    text-align: center;
    width: 100px;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:10px 5px 10px 5px;
    border-radius: 5px;
    background-color: transparent;
    font-size: 10px;
    font-weight: bold;
    color: #1877F2;
    cursor: pointer;
}



.AccreditorVerificationList-ActionButton-SecondaryButtonSmall{
    text-align: center;
    display:inline-block;
    padding:5px 10px 5px 10px;
    margin:10px 5px 10px 5px;
    border: 1px solid #1B91FF;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 10px;
    font-weight: bold;
    color: #1B91FF;
    cursor: pointer;
}

#AccreditorVerificationList tr:first-child {
    background-color: #ECECEC;;
}

#AccreditorVerificationList tr:nth-child(even) {
    background-color: #F6F6F6;;
}

#AccreditorVerificationList .thRow {
  padding: 10px 15px 10px 15px;
  border-right: 1px solid #dddddd;
}

#AccreditorVerificationList .thRowLast {
    padding: 10px 15px 10px 15px;
}
  
#AccreditorVerificationList th {
    border-bottom: 1px solid #dddddd;
    border-left: 0px;
    border-right: 0px;
    padding-top:5px;
    padding-bottom:5px;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #666666;
}

#AccreditorVerificationList td{
    border: 0px;
    padding: 20px 15px 5px 15px;
    font-size: 12px;
    text-align: left;
    color: #000000;
}

#AccreditorVerificationList td:last-child{
    border: 0px;
    padding: 5px 15px 5px 15px;
    font-size: 12px;
    text-align: left;
    color: #000000;
}

.AccreditorVerificationList-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

#AccreditorVerificationList-TextInput{
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
}

#None {
    display:none;
}

#AccreditorVerificationList-Pagination{
    display:block;
    text-align: left;
  }
  #AccreditorVerificationList-Pagination::after{
    display:table;
    content:"";
    clear:both;
  }
  #AccreditorVerificationList-Pagination ul{
    display:block;
    padding:0px;
    margin:15px 30px 0px 0px;
  }
  
  #AccreditorVerificationList-Pagination .AccreditorVerificationList-ActiveLi{
    font-family: 'Poppins', 'Roboto', sans-serif;
    list-style-type: none;
    display:inline-block;
    min-width:30px;
    border: 1px solid #1B91FF;
    background-color: #1B91FF;
    border-radius: 5px;
    padding:5px;
    margin:5px;
    text-align: center;
    color:white;
    cursor:pointer;
  }
  #AccreditorVerificationList-Pagination .AccreditorVerificationList-InactiveLi {
    font-family: 'Poppins', 'Roboto', sans-serif;
    list-style-type: none;
    display:inline-block;
    min-width:30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding:5px;
    margin:5px;
    text-align: center;
    color:#929292;
    cursor:pointer;
  }