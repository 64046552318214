#AddListingForm{
    display:block;
}

.AddListing-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.AddListing-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.AddListing-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.AddListing-Body{
    display:block;
    padding:30px;
}

.AddListing-InputLayout{
    display:block;
    margin-bottom:15px;
}

.AddListing-InputLabelSmall {
    font-size: 12px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:5px;
}

.AddListing-TextInput{
    font-size: 12px;
    text-align: left;
    color: #212121;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -moz-appearance:textfield;
}

.AddListing-NumberInput {
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    -webkit-appearance: none; 
}
.AddListing-NumberInput::-webkit-inner-spin-button,
.AddListing-NumberInput::-webkit-outer-spin-button{
    -webkit-appearance: none; 
}


.AddListing-InputLayout{
    display:block;
}

.AddListing-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

.AddListing-InputLayout-Left{
    display:block;
    width:50%;
    float:left;
}

.AddListing-InputLeft{
    display:block;
    margin-right:15px;
}

.AddListing-InputLayout-Right{
    display:block;
    width:50%;
    float:right;
}

.AddListing-InputRight{
    display:block;
    margin-left:15px;
}

.AddListing-ErrorText{
    display:block;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #FF5A26;
}


.AddListing-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.AddListing-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.AddListing-CancelButton{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
}

.AddListing-SubmitButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.AddListing-CancelButtonDisabled{
    display:block;
    float:left;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.AddListing-SubmitButtonDisabled{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #E6E6E6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.Gone {
    display:none;
}
#AddListing-Hint{
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
}