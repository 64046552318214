#SuppliesTableContainer{
    margin: 20px;
    background-color: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

#SuppliesTableContainer-HeaderRow{
    display:block;
    text-align: left;
    width:100%;
    padding:30px;
}

#SuppliesTableContainer-HeaderRow::after{
    display:table;
    content:"";
    clear:both;
}

.SuppliesTableContainer-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    color:#000000;
    margin-bottom: 20px;
}

.SuppliesTableContainer-InputHolderSmall{
    display: block;
    width:100px;
    float:left;
    margin-left:15px;
}

.SuppliesTableContainer-InputHolderMedium{
    display: block;
    width:205px;
    float:left;
    margin-left:15px;
}

.SuppliesTableContainer-InputHolderLarge{
    display: block;
    width:425px;
}

#SuppliesTableSearch-InputLabelSmall{
    display:block;
    font-size:12px;
    font-weight: bold;
    color:#000000;
    text-align: left;
    margin-bottom:5px;
}

#SuppliesTableSearch-SearchTextInput{
    font-size: 12px;
    text-align: left;
    color: #000000;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.SuppliesTableContainer-ItemCountLabel{
    display: block;
    margin-top:15px;
    float:left;
    font-size: 12px;
    color: #000000;
}

#SuppliesTableContainer-Body{
    padding:30px;
    text-align:center;
    height:670px;
    overflow:auto;
}

#SuppliesTable{
    border-collapse: collapse;
    width: 100%;
    border:0px;
    border: 1px solid #dddddd;
}

#SuppliesTable tr:first-child {
    background-color: #ECECEC;;
}

#SuppliesTable tr:nth-child(even) {
    background-color: #F6F6F6;;
}

#SuppliesTable .thRow {
  padding: 10px 15px 10px 15px;
  border-right: 1px solid #dddddd;
}
#SuppliesTable .thRowLast {
    padding: 10px 15px 10px 15px;
  }
  
#SuppliesTable th {
    border-bottom: 1px solid #dddddd;
    border-left: 0px;
    border-right: 0px;
    padding-top:5px;
    padding-bottom:5px;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #666666;
}

#SuppliesTable td{
    border: 0px;
    padding: 20px 15px 0px 15px;
    font-size: 12px;
    text-align: left;
    color: #000000;
}

#SuppliesTable td:last-child{
    border: 0px;
    padding: 5px 15px 5px 15px;
    font-size: 12px;
    text-align: left;
    color: #000000;
}

.SuppliesTable-ProgressValue{
    display:block;
    text-align: left;
    margin-bottom:5px;
}

#SuppliesTable-ProgressBar{
    display:block;
    width:100%;
    height:4px;
    background-color:#1B91FF;
}
#SuppliesTable-ProgressBarComplete{
    display:block;
    width:100%;
    height:4px;
    background-color: #8CD867;
}
#SuppliesTable-ProgressBar::-webkit-progress-bar{
    background-color: #D7D7D7;
}
#SuppliesTable-ProgressBar::-webkit-progress-value{
    background: #1B91FF;
}
#SuppliesTable-ProgressBarComplete::-webkit-progress-value{
    background: #8CD867;
}
#SuppliesTable-ProgressBar::-moz-progress-bar{
    background: #D7D7D7;
}
#SuppliesTable-ProgressBar::-moz-progress-value{
    background: #1B91FF;
}
#SuppliesTable-ProgressBarComplete::-webkit-progress-value{
    background: #8CD867;
}

.SuppliesTable-MoreDetails{
    margin: 10px;
    display:inline-block;
    width: 115px;
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
    background-color: transparent;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: #1B91FF;
    cursor: pointer;
}

.SuppliesTable-ContactVendor{
    margin: 10px;
    display:inline-block;
    width: 115px;
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
    background-color: #1B91FF;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.SuppliesTable-ActionButtonCompleted{
    margin: 10px;
    display:inline-block;
    width: 115px;
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
    background-color: #8CD867;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: white;
}

#SuppliesTable-Pagination{
  display:block;
  text-align: left;
}
#SuppliesTable-Pagination::after{
  display:table;
  content:"";
  clear:both;
}
#SuppliesTable-Pagination ul{
  display:block;
  padding:0px;
  margin:0px 30px 30px 30px;
}

#SuppliesTable-Pagination .SuppliesTable-ActiveLi{
  font-family: 'Poppins', 'Roboto', sans-serif;
  list-style-type: none;
  display:inline-block;
  min-width:30px;
  border: 1px solid #1B91FF;
  background-color: #1B91FF;
  border-radius: 5px;
  padding:5px;
  margin:5px;
  text-align: center;
  color:white;
  cursor:pointer;
}
#SuppliesTable-Pagination .SuppliesTable-InactiveLi {
  font-family: 'Poppins', 'Roboto', sans-serif;
  list-style-type: none;
  display:inline-block;
  min-width:30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding:5px;
  margin:5px;
  text-align: center;
  color:#929292;
  cursor:pointer;
}

.SuppliesTable-Loading{
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

.SuppliesTable-VerifiedIconHolder{
    position:relative;
    display: inline-block;
    margin-left: 3px;
}

.SuppliesTable-VerifiedIcon{
    height:14px;
    width:14px;
    margin-bottom: 3px;
}

.SuppliesTable-VerifiedIconHolder:hover{
    text-decoration: underline;
    cursor: pointer;
 }

.SuppliesTable-VerifiedIconHolder:hover .SuppliesTable-Tooltip{
    visibility: visible;
}

.SuppliesTable-Tooltip{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    visibility: hidden; 
    width:185px;
    height:fit-content; 
    background-color: #2F2F2F;
    color: #ffffff;
    text-align: center;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    bottom: 160%;
    left: -150%;
    margin-left: -60px;
    padding:10px;
}
.SuppliesTable-Tooltip::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 45%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #2F2F2F transparent transparent transparent;
}