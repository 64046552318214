

.ContactInformation-Header{
    display:block;
    padding:30px;
    background-color:#F4F4F4;
    border-radius: 20px 20px 0px 0px;
}

.ContactInformation-PreHeaderTitle {
    font-size: 14px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #666666;
    margin-bottom:5px;
}

.ContactInformation-HeaderTitle {
    font-size: 20px;
    font-weight: bold;
    display:block;
    text-align:left;
    color: #212121;
}

.ContactInformation-Body{
    display:block;
    padding:30px;
}
.ContactInformation-InputLabelSmall {
    font-size: 12px;
    font-weight: 600;
    display:block;
    text-align:left;
    color: #212121;
    margin-bottom:5px;
}

.ContactInformation-InputValue{
    font-size: 14px;
    text-align: left;
    color: #000000;
    margin-bottom:30px;
}

.ContactInformation-InputValueBlue{
    font-size: 14px;
    text-align: left;
    color: #1B91FF;
    margin-bottom:30px;
}



.ContactInformation-InputLayout{
    display:block;
}

.ContactInformation-InputLayout::after{
    display:table;
    content:"";
    clear:both;
}

.ContactInformation-InputLayout-Left{
    display:block;
    width:50%;
    float:left;
}

.ContactInformation-InputLeft{
    display:block;
    margin-right:15px;
}

.ContactInformation-InputLayout-Right{
    display:block;
    width:50%;
    float:right;
}

.ContactInformation-InputRight{
    display:block;
    margin-left:15px;
}

.ContactInformation-Loading {
    display:block;
    text-align: center;
    padding:30px;
    font-size:20px;
    font-weight: bold;
}

.ContactInformation-Footer{
    display:block;
    padding:30px;
    border-top: 1px solid #C4C4C4;
}

.ContactInformation-Footer::after {
    display:table;
    content:"";
    clear:both;
}

.ContactInformation-CloseButton{
    display:block;
    float:right;
    padding: 5px 25px 5px 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #1877F2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}
