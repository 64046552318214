#ResetLinkPage{
    margin: auto;
    margin-top: 50px;
    display: block;
    width: 420px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  
  #ResetLinkPageContents1 {
    display: block;
    padding: 30px 30px 0px 30px;
  }
  
  #ResetLinkPageContents2 {
    display: block;
    padding:30px;
  }
  
  #ResetLinkPage-Logo {
    width: 250px;
    height: 100px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }
  
  #ResetLinkPage-Title {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    padding: 30px 30px 15px 30px;
    color: #000000;
    border-top: 1px solid #dbdbdb;
  }
  
  #ResetLinkPage-SaveButton {
    height: 43px;
    margin-top: 10px;
    text-align: center;
    display: block;
    padding: 10px;
    border-radius: 5px;
    background: #1b91ff;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }
  
  #ResetLinkPage-SaveButton:hover {
    background: #176bd8;
  }
  
  #ResetLinkPageContents-SaveButtonDisabled {
    height: 43px;
    margin-top: 15px;
    text-align: center;
    display: block;
    padding: 12px;
    border-radius: 5px;
    background-color: #1877f2;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }
  
  #ResetLinkPageContents-SaveButtonLoading {
    margin: auto;
    border: 3px solid #ffffff;
    border-top: 3px solid transparent;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  #ResetLinkPage-Header {
    margin: 0 auto;
    width: 420px;
    padding-top: 62px;
    margin-bottom: -20px;
  }
  
  #ResetLinkPage-HeaderTitle {
    font-size: 14px;
    line-height: 19px;
    color: #1b91ff;
    cursor: pointer;
  }
  
  #ResetLinkPage-HeaderIcon {
    width: 15.34px;
    height: 15.34px;
    margin: 0px 5px 2px 0px;
  }
  
  #ResetLinkPage-Body {
    background: #f5f5f5;
    min-height: 100vh;
  }
  
  .ResetLinkPage-InputLabel {
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    font-weight: bold;
  }

  .ResetLinkPage-InputPass {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 360px;
    height: 37px;
    padding-left: 10px;
    padding-right: 50px;
    border: 1px solid #dbdbdb;
  }

  .ResetLinkPage-InputErrorPass {
    margin-top: 10px;
    width: 360px;
    height: 37px;
    padding-left: 10px;
    padding-right: 50px;
    border: 1px solid #ff5a26;
  }
  
  #ResetLinkPageContents-Description {
    background: #ebf5ff;
    text-align: center;
    align-items: center;
  }
  
  #ResetLinkPage-PasswordInformation-Description {
    font-size: 10px;
    color: #4f4f4f;
    padding: 15px 35px 15px 35px;
  }
  
  #ResetLinkPage-ResetLabel {
    font-size: 12px;
    text-align: center;
  color : #1B91FF;
  }
  
  #ResetLinkPage-ResetLabel:hover {
      text-decoration: underline;
      color : #1B91FF;
      cursor: pointer;
  }
  
  #ResetLinkPage-Reset {
      font-size: 12px;
      text-align: center;
      padding: 15px 15px 15px 15px;
    }
  
  .ResetLinkPage-InputErrorMessage {
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: #ff5a26;
    padding-top: 5px;
    margin-bottom: 10px;
  }
  
  .ResetLinkPage-InputContainer {
    width: 360px;
  }
  