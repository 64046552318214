.statistics-page-content {
    font-size: 12px;
    border-color: black;
    margin: 16px 0px;
}

div.page-header.page-row {
    display:block;
    text-align: left;
    width:100%;
    font-size: 20px;
    font-weight: bold;
    color:#000000;
    background-color: white;
}

@media only screen and (max-width: 600px) { 
    .page-requests {
        position: relative;
        display:block;
        height:100vh;
        width:100%;
    }

    .statistic-data-text {
        text-align: center;
    }
    
    .start-content {
        margin-top: 70px;
    }
    
    .statistic-count-label {
        padding: 0px 5%;
        border-color: black;
        font-size: 24px;
        font-weight: bold;
    }
    
    .end-content {
        padding-bottom: 50px;
    }
    
    .statistics-header {
        color: gray;
        text-align: center;
    }
    
    .statistic-main-count {
        font-size: 48px;
        font-weight:bold;
    }
    
    .statistic-main-label {
        font-size: 16px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 750px) and (max-height: 630px) { 
    .page-container {
        display: inline-block;
        width: 100%;
        background-color: #EEECEC;
    }

    .page-row {
        padding: 24px 30px;
        display: inline-block;
        width: 50%;
    }

    .statistic-count-label {
        padding: 0px 5%;
        border-color: black;
        font-size: 24px;
        font-weight: bold;
    }
    
    .end-content {
        padding-bottom: 50px;
    }
    
    .statistics-header {
        color: black;
        text-align: center;
    }
    
    .statistic-main-count {
        font-size: 24px;
        font-weight:bold;
    }
    
    .statistic-main-label {
        font-size: 12px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 750px) and (min-height: 631px) { 
    .page-container {
        display: inline-block;
        width: 100%;
        background-color: #EEECEC;
    }

    .page-row {
        padding: 30px 30px;
        display: inline-block;
        width: 50%;
    }

    .statistic-count-label {
        padding: 0px 5%;
        border-color: black;
        font-size: 24px;
        font-weight: bold;
    }
    
    .end-content {
        padding-bottom: 50px;
    }
    
    .statistics-header {
        color: black;
        text-align: center;
    }
    
    .statistic-main-count {
        font-size: 48px;
        font-weight:bold;
    }
    
    .statistic-main-label {
        font-size: 16px;
    }
}

@media only screen and (min-width: 750px) and (max-width: 1140px) and (max-height: 630px) { 
    .page-container {
        display: inline-block;
        width: 100%;
        margin-top: -7%;
        background-color: #EEECEC;
    }

    .page-row {
        padding: 16px 30px;
        display: inline-block;
        width: 30%;
    }

    .statistic-count-label {
        padding: 0px 5%;
        border-color: black;
        font-size: 24px;
        font-weight: bold;
    }
    
    .end-content {
        padding-bottom: 50px;
    }
    
    .statistics-header {
        color: black;
        text-align: center;
    }
    
    .statistic-main-count {
        font-size: 24px;
        font-weight:bold;
    }
    
    .statistic-main-label {
        font-size: 12px;
    }
}

@media only screen and (min-width: 750px) and (max-width: 1140px) and (min-height: 631px) { 
    .page-container {
        display: inline-block;
        width: 100%;
        background-color: #EEECEC;
    }

    .page-row {
        padding: 30px 30px;
        display: inline-block;
        width: 30%;
    }

    .statistic-count-label {
        padding: 0px 5%;
        border-color: black;
        font-size: 24px;
        font-weight: bold;
    }
    
    .end-content {
        padding-bottom: 50px;
    }
    
    .statistics-header {
        color: black;
        text-align: center;
    }
    
    .statistic-main-count {
        font-size: 48px;
        font-weight:bold;
    }
    
    .statistic-main-label {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1140px) and (max-height: 630px)  {
    .page-requests {
        height:auto;
    }
    
    .page-container {
        display: inline-block;
        width: 1140px;
        background-color: #EEECEC;
    }

    .page-row {
        padding: 24px 30px;
        display: inline-block;
        width: 30%;
    }

    .statistic-count-label {
        padding: 0px 5%;
        border-color: black;
        font-size: 24px;
        font-weight: bold;
        
    }
    
    .end-content {
        padding-bottom: 50px;
    }
    
    .statistics-header {
        color: black;
        text-align: center;
    }
    
    .statistic-main-count {
        font-size: 24px;
        font-weight:bold;
    }
    
    .statistic-main-label {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1140px) and (min-height: 631px)  {
    .page-requests {
        height:auto;
    }
    .page-container {
        display: inline-block;
        width: 1140px;
        background-color: #EEECEC;
    }

    .page-row {
        padding: 30px 30px;
        display: inline-block;
        width: 30%;
    }

    .statistic-count-label {
        padding: 0px 5%;
        border-color: black;
        font-size: 24px;
        font-weight: bold;
        
    }
    
    .end-content {
        padding-bottom: 50px;
    }
    
    .statistics-header {
        color: black;
        text-align: center;
    }
    
    .statistic-main-count {
        font-size: 48px;
        font-weight:bold;
    }
    
    .statistic-main-label {
        font-size: 16px;
    }
}