#UserProfile {
  display: block;
  width: 100%;
  height: 100vh;
}

#UserProfileContainer {
  display: block;;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 600px;
  border-radius: 25px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}

.UserProfile-Header {
  display: block;
  padding: 30px;
  background-color: #f4f4f4;
  border-radius: 20px 20px 0px 0px;
}

.UserProfile-PreHeaderTitle {
  font-size: 14px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #666666;
  margin-bottom: 5px;
}

.UserProfile-HeaderTitle {
  font-size: 20px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #212121;
}

.UserProfile-Body {
  display: block;
  padding: 30px 30px 10px 30px;
  margin-bottom: 10%;
}

.UserProfile-InputLabelSmall {
  font-size: 12px;
  font-weight: 600;
  display: block;
  text-align: left;
  color: #212121;
  margin-bottom: 5px;
  font-weight: bold;
}


.UserProfile-InputValue {
  font-size: 14px;
  text-align: left;
  color: #000000;
  margin-bottom: 30px;
}

.UserProfile-InputValueBlue {
  font-size: 14px;
  text-align: left;
  color: #1b91ff;
  margin-bottom: 30px;
}

.UserProfile-InputLayout {
  display: block;
}

.UserProfile-Input-Layout{
  display: block;
  margin-top: 2%;
  margin-bottom: 2%;
}

.UserProfile-InputLayout::after {
  display: table;
  content: "";
  clear: both;
}

.UserProfile-InputLayout-Left {
  display: block;
  width: 50%;
  float: left;
}

.UserProfile-InputLeft {
  display: block;
  margin-right: 15px;
}

.UserProfile-InputLayout-Right {
  display: block;
  width: 50%;
  float: right;
}

.UserProfile-InputRight {
  display: block;
  margin-left: 15px;
}

.UserProfile-Institution {
  display: block;
  padding-top: 30px;
  border-top: 1px solid #c4c4c4;
}

.UserProfile-Loading {
  display: block;
  text-align: center;
  padding: 30px;
  font-size: 20px;
  font-weight: bold;
}

.Gone {
  display: none;
}

.UserProfile-InputLayout-Bottom {
  display: block;
  padding: 30px 0px 0px 0px;
  border-top: 1px solid #c4c4c4;
}

.UserProfile-InputLayout-Bottom::after {
  display: table;
  content: "";
  clear: both;
}

.Gone {
    display: none;
}

.UserProfile-ButtonsLayout {
    display: block;
}

#UserProfile-SaveButton {
    display: block;
    text-align: center;
    width:40%;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    background-color: #1877f2;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    margin: 20px auto;
    float: right;
}

#UserProfile-SaveButtonDisabled {
    display: block;
    float: right;
    margin-top: 50px;
    text-align: center;
    margin: 30px;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    background-color: #e6e6e6;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

#UserProfile-HasSubscribed{
    margin-right:10px;
}

#UserProfile-CheckboxLabelLeft {
    display:inline-block;
    padding:5px;
    margin-right:50px;
    box-sizing: border-box;
    font-size: 14px;
    color: #000000; 
    cursor: pointer;
}

.UserProfile-InputLayout-Bottom-Label {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.UserProfile-InputLayout-BottomValue {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

/*CUSTOM RADIO BUTTON*/
.container {
  position: relative;
  padding-left: 35px;
  margin-left: 15px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000000;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: solid 2px #CCC;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: transparent;
  border: solid 2px #1877f2;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 2px;
	left: 2px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
  background: #1877f2;
}


