#AlreadyRegisteredPrompt {
  display: block;
}

.AlreadyRegisteredPrompt-Body {
  display: block;
  padding: 30px 30px 15px 30px;
  text-align: center;
}

.AlreadyRegisteredPrompt-Body img {
  width: 50px;
  height: 50px;
}

.AlreadyRegisteredPrompt-Title {
  display: block;
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.AlreadyRegisteredPrompt-Description {
  font-size: 14px;
  color: #000000;
  margin-bottom: 15px;
}

.AlreadyRegisteredPrompt-Footer {
  display: block;
  padding: 0px 30px 30px 30px;
}

.AlreadyRegisteredPrompt-Footer::after {
  display: table;
  content: "";
  clear: both;
}

.AlreadyRegisteredPrompt-CancelButton {
  display: block;
  float: left;
  padding: 5px 30px 5px 30px;
  text-align: center;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}

.AlreadyRegisteredPrompt-SubmitButton {
  display: block;
  padding: 6px 30px 5px 30px;
  width: 80px;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
  background-color: #1877f2;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
