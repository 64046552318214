#LandingPageHeader {
    font-weight: bold;
    display:block;
    width:100%;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#LandingPageHeader::after{
    display:table;
    content:"";
    clear:both;
}

.LandingPageHeader-Left {
    display:block;
    width:40%;
    float:left;
}

.LandingPageHeader-Right {
    display:block;
    width:60%;
    float:right;
}

.LandingPageHeader-Left img{
    height:60px;
    width:200px;
    margin:15px 10px 10px 30px;
    display:block;
    float:left;
}

.LandingMenuItem {
    display:block;
    float:right;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    padding:30px;
    margin: 0px;
    display: block;
    color: #000000;
    text-align: center;
    text-decoration: none;
}


